import Worker from 'worker-loader!@/workers/variables.js'

export default {
    data() {
        return {
            worker: new Worker()
        }
    },
   
    methods: {
        inject_variable(containerId, variable) {
            // document.execCommand('insertText', false, `{{${variable.key}}}`);
            this.insertTextAtCursor(containerId, `{{${variable.key}}}`);
        },
        insertTextAtPosition(el, text, position) {
            var div = document.getElementById(el);
            var node = document.createTextNode(text);
            var range = document.createRange();
            var sel = window.getSelection();
            range.setStart(div.childNodes[0], position);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            div.focus();
            document.execCommand('insertText', false, text);
        },
        insertTextAtCursor(textareaId, textToInsert) {
            const element = document.getElementById(textareaId);
            if (!element) return;
        
            const selection = window.getSelection();
            if (!selection.rangeCount) return;
        
            const range = selection.getRangeAt(0);
            range.deleteContents();
        
            const textNode = document.createTextNode(textToInsert);
            range.insertNode(textNode);
        
            range.setStartAfter(textNode);
            range.setEndAfter(textNode); 
            selection.removeAllRanges();
            selection.addRange(range);
        
            this.highlight_default_prompt();
        },
        highlightVariables(textareaId, variables) {
            var textarea = document.getElementById(textareaId);
            if (!textarea) return;
            this.sendWorkerJob('highlightVariables', textareaId, variables, textarea.innerHTML);
        },
        previewVariables(textareaId, variables) {
            var textarea = document.getElementById(textareaId);
            if (!textarea) return;
            this.sendWorkerJob('previewVariables', textareaId, variables, textarea.innerHTML);
        },
        sendWorkerJob(action, textareaId, variables, html) {
            this.worker.postMessage({
                action: action,
                textareaId: textareaId,
                variables: JSON.stringify(variables),
                html: html
            });
        }
    }
}   