<template>
    <aside id="logo-sidebar"
        class="fixed top-0 left-0 bottom-0 z-50 w-64 h-screen mt-[42px] lg:mt-[51px] pt-2 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
        aria-label="Sidebar">
        <div class="flex flex-col relative h-full">
            <div class="px-0 pl-2 pt-1 flex-grow w-full">
                <button type="button" style="width: calc(100% - 0.6rem);
" class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm ps-5 py-2.5 text-center me-2 mb-2 w-full flex flex-row"
                    @click="create_chat">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-chat-fill mr-4 transform scale-150 translate-y-0.5" viewBox="0 0 16 16">
                        <path
                            d="M2.5 0c-.166 0-.33.016-.487.048l.194.98A1.51 1.51 0 0 1 2.5 1h.458V0zm2.292 0h-.917v1h.917zm1.833 0h-.917v1h.917zm1.833 0h-.916v1h.916zm1.834 0h-.917v1h.917zm1.833 0h-.917v1h.917zM13.5 0h-.458v1h.458c.1 0 .199.01.293.029l.194-.981A2.51 2.51 0 0 0 13.5 0m2.079 1.11a2.511 2.511 0 0 0-.69-.689l-.556.831c.164.11.305.251.415.415l.83-.556zM1.11.421a2.511 2.511 0 0 0-.689.69l.831.556c.11-.164.251-.305.415-.415L1.11.422zM16 2.5c0-.166-.016-.33-.048-.487l-.98.194c.018.094.028.192.028.293v.458h1zM.048 2.013A2.51 2.51 0 0 0 0 2.5v.458h1V2.5c0-.1.01-.199.029-.293l-.981-.194zM0 3.875v.917h1v-.917zm16 .917v-.917h-1v.917zM0 5.708v.917h1v-.917zm16 .917v-.917h-1v.917zM0 7.542v.916h1v-.916zm15 .916h1v-.916h-1zM0 9.375v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .916v.917h1v-.917zm16 .917v-.917h-1v.917zm-16 .917v.458c0 .166.016.33.048.487l.98-.194A1.51 1.51 0 0 1 1 13.5v-.458zm16 .458v-.458h-1v.458c0 .1-.01.199-.029.293l.981.194c.032-.158.048-.32.048-.487M.421 14.89c.183.272.417.506.69.689l.556-.831a1.51 1.51 0 0 1-.415-.415l-.83.556zm14.469.689c.272-.183.506-.417.689-.69l-.831-.556c-.11.164-.251.305-.415.415l.556.83zm-12.877.373c.158.032.32.048.487.048h.458v-1H2.5c-.1 0-.199-.01-.293-.029zM13.5 16c.166 0 .33-.016.487-.048l-.194-.98A1.51 1.51 0 0 1 13.5 15h-.458v1zm-9.625 0h.917v-1h-.917zm1.833 0h.917v-1h-.917zm1.834-1v1h.916v-1zm1.833 1h.917v-1h-.917zm1.833 0h.917v-1h-.917zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                    </svg>
                    New chat
                </button>
                <div class="overflow-y-auto -mb-5" style="height: calc(100% - 100px)">
                    <ul class="flex flex-col w-full font-medium pe-2 pb-5">
                        <li v-for="chat in chats" class="w-full">
                            <div @click.stop.prevent="load_chat(chat._id)" @dblclick.stop.prevent="rename_chat(chat)"
                                class="cursor-pointer flex flex-row justify-between items-center text-gray-900 rounded-lg dark:text-gray-400 group w-full"
                                :class="{
                                    'bg-gray-100 dark:bg-gray-700 dark:text-gray-100 font-semibold ': $route.params.chatId == chat._id && !renaming_chat,
                                    'hover:bg-gray-100 dark:hover:bg-gray-700 font-regular dark:hover:bg-opacity-50 dark:hover:text-white': !(renaming_chat && renaming_chat_id == chat._id),
                                    'dark:hover:bg-none': renaming_chat,
                                }">
                                <div class="w-full">
                                    <div v-if="renaming_chat && renaming_chat_id == chat._id">
                                        <input type="text"
                                            class="z-40 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1 px-2 pe-6 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 border-r-0 rounded-r-none m-0"
                                            v-model="rename_chat_name"
                                            @keyup.enter="renaming_chat = false; $store.dispatch('renameChat', { chatId: chat._id, name: rename_chat_name })">
                                    </div>
                                    <div v-else class="ms-3 py-1 text-xs break-words line-clamp-2"
                                        :title="chat.metadata.name"
                                        :class="{ 'bg-gradient-to-r from-blue-300 via-blue-400 to-blue-500  text-transparent bg-clip-text': chat._id == current_chat?._id }">
                                        {{ chat.metadata.name }}
                                    </div>
                                </div>
                                <div v-show="!(renaming_chat && renaming_chat_id == chat._id)"
                                    :data-dropdown-toggle="'dropdownSkidding_toggle_' + chat._id"
                                    :id="'dropdownSkidding_menu_' + chat._id" data-dropdown-offset-distance="0"
                                    data-dropdown-offset-skidding="0" data-dropdown-placement="right" @click.stop=""
                                    class="w-8 h-6 mt-1.5">
                                    <svg class="w-4 h-4 text-gray-500 dark:text-gray-400 mx-auto" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
                                        <path
                                            d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                                    </svg>
                                </div>
                                <div v-show="renaming_chat && renaming_chat_id == chat._id">
                                    <div class="inline-flex rounded-md shadow-sm pt-1.5">
                                        <a href="#" @click.stop="renaming_chat = false;"
                                            class="px-1 py-1.5 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                                                <path
                                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </a>
                                        <a href="#"
                                            @click.stop="renaming_chat = false; $store.dispatch('renameChat', { chatId: chat._id, name: rename_chat_name });"
                                            class="px-1 py-1.5 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-check-lg" viewBox="0 0 16 16">
                                                <path
                                                    d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div :id="'dropdownSkidding_toggle_' + chat._id"
                                class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 shadow-lg">
                                <ul class="text-sm text-gray-700 dark:text-gray-200"
                                    :aria-labelledby="'dropdownSkidding_toggle_' + chat._id">
                                    <li>
                                        <a href="#"
                                            class="block px-4 py-1 hover:rounded-t-lg hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                            @click="rename_chat(chat)">Rename</a>
                                    </li>
                                    <li>
                                        <a href="#"
                                            class="block px-4 py-1 hover:bg-gray-100 border-t border-gray-200 dark:border-gray-800 dark:hover:bg-gray-600 dark:hover:text-white"
                                            @click="duplicate_chat(chat)">Duplicate</a>
                                    </li>
                                    <li>
                                        <a href="#"
                                            class="block px-4 py-1 hover:rounded-b-lg hover:bg-gray-100 border-t border-gray-200 dark:border-gray-800 dark:bg-red-950 dark:bg-opacity-50 dark:text-red-400 rounded-b-lg dark:hover:text-red-300"
                                            @click="showConfirmModal(chat)">Delete</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </aside>
    <ConfirmModal title="Delete chat" content="Are you sure you want to delete this chat ?" :item="confirmItem"
        v-if="Object.keys(confirmItem).length > 0" :isVisible="showModalConfirm"
        @update:isVisible="handleConfirmModalVisibility" @update:confirmed="delete_chat">
    </ConfirmModal>
</template>
<script>
import { initDropdowns, Dropdown } from 'flowbite'
import ConfirmModal from '@/components/modals/confirm.vue';

export default {
    components: {
        ConfirmModal
    },
    data() {
        return {
            chats: [],
            current_chat: null,
            rename_chat_name: '',
            renaming_chat_id: null,
            renaming_chat: false,
            confirmItem: {},
            showModalConfirm: false,
        }
    },
    async mounted() {
        await this.$store.dispatch('fetchChats');
        initDropdowns();

        this.$nextTick(() => {
            document.body.addEventListener('keyup', (e) => {
                if (e.key === 'Escape' && this.renaming_chat) {
                    this.renaming_chat = false;
                }
            });
        });
    },
    computed: {
        chats_computed() {
            return this.$store.state.chats
        },
        current_chat_computed() {
            return this.$store.state.current_chat
        }
    },
    watch: {
        current_chat_computed(val) {
            this.current_chat = val;
        },
        chats_computed(val) {
            this.chats = val;
        },
    },
    methods: {
        async create_chat() {
            let chat = await this.$store.dispatch('createChat');
            this.$router.push({ name: this.$route.name != 'home' ? this.$route.name : 'variables', params: { chatId: chat._id } })
            initDropdowns();
        },
        async delete_chat(chat) {
            await this.$store.dispatch('deleteChat', { chatId: chat._id })
            if (chat._id == this.current_chat?._id)
                await this.$store.commit('setCurrentChat', null)
            this.confirmItem = {};
            this.hideDropdown(chat._id);
            this.$store.commit('deleteChat', chat._id)

        },
        async duplicate_chat(chat) {
            await this.$store.dispatch('duplicateChat', { chatId: chat._id })
            this.$nextTick(() => {
                this.hideDropdown(chat._id);
            });
        },
        async load_chat(chat_id) {
            if (!this.renaming_chat) {
                await this.$store.dispatch('fetchChat', { chatId: chat_id })
                this.$nextTick(() => {
                    this.$router.push({ name: this.$route.name != 'home' ? this.$route.name : 'variables', params: { chatId: chat_id } })
                });
            }
        },
        async rename_chat(chat) {
            this.renaming_chat = true;
            this.renaming_chat_id = chat._id;
            this.rename_chat_name = chat.metadata.name;
            this.hideDropdown(chat._id);
        },
        hideDropdown(chat_id) {
            this.$nextTick(() => {
                const target = document.querySelector('#dropdownSkidding_toggle_' + chat_id);
                const trigger = document.querySelector('#dropdownSkidding_menu_' + chat_id);
                if (!target || !trigger) return;
                const dropdown = new Dropdown(target, trigger, {
                    override: true,
                    triggerType: 'click',
                });
                dropdown.hide();
            });
        },
        handleConfirmModalVisibility(val) {
            this.showModalConfirm = val;
        },
        showConfirmModal(chat) {
            this.confirmItem = chat;
            this.hideDropdown(chat._id);
            this.showModalConfirm = true;
        }
    }
}
</script>