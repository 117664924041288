<template>
    <ModalComponent :title="title" :isVisible="isVisible" @update:isVisible="handleVisibility">
        <template v-slot:title>
            {{ title }}
        </template>
        <template v-slot:body>
            <div class="flex flex-col lg:grid lg:grid-cols-[180px_minmax(1vw,_1fr)_0px] lg:gap-0 w-full">
                <div class="text-gray-900 bg-white dark:bg-gray-700 dark:text-gray-400 flex lg:flex-col flex-row">
                    <button type="button" @click="current_category = category.name"
                        v-for="(category, index) in categories"
                        class="relative inline-flex items-center w-full px-4 py-2 text-sm font-medium border-b border-gray-100 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:text-white whitespace-nowrap"
                        :class="{ 'dark:bg-gray-600 dark:text-white': current_category == category.name, 'lg:border-none': index == categories.length - 1 }">
                        <div v-html="category.icon" class="mr-3"></div>
                        {{ category.name }}
                    </button>
                </div>
                <div class="relative col-start-2 w-full" v-if="current_category == 'Pricings'">
                    <div class="m-4 flex items-center p-2 text-sm text-blue-800 border border-blue-500 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-300 dark:text-opacity-50 dark:border-blue-300 dark:border-opacity-10"
                        role="alert">
                        <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span class="sr-only">Info</span>
                        <div>
                            <span class="font-medium">The pricings are in $/1000 tokens</span>
                        </div>
                    </div>
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead
                            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700  dark:bg-opacity-50 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-2.5">
                                    Model
                                </th>
                                <th scope="col" class="px-6 py-2.5 text-center">
                                    Input
                                </th>
                                <th scope="col" class="px-6 py-2.5 text-center">
                                    Output
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-for="price in prices">
                                <th scope="row"
                                    class="px-6 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-gray-400">
                                    {{ price.model }}
                                </th>
                                <td class="px-1 py-1">
                                    <input type="text"
                                        class="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        v-model="price.input">
                                </td>
                                <td class="px-1 py-1">
                                    <input type="text"
                                        class="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        v-model="price.output">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="relative overflow-x-auto p-4 w-full" v-if="current_category == 'Backup & Restore'">
                    <div>
                        <h6 class="text-md dark:text-gray-500 font-medium">Backup the database</h6>
                        <div class="flex flex-col mt-3">
                            <input type="search" id="search"
                                v-model="backup_filename"
                                class="block w-full p-4 py-3 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Filename" required />
                            <button
                                @click.stop.prevent="backup()"
                                class="mt-2 w-full flex flex-row justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-auto">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white mr-2" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                    viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M19 10V4a1 1 0 0 0-1-1H9.914a1 1 0 0 0-.707.293L5.293 7.207A1 1 0 0 0 5 7.914V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2M10 3v4a1 1 0 0 1-1 1H5m5 6h9m0 0-2-2m2 2-2 2" />
                                </svg>
                                Export
                            </button>
                        </div>

                    </div>
                    <div class="mt-5">
                        <h6 class="text-md dark:text-gray-500 font-medium">Restore the database</h6>
                        <div class="flex flex-col mt-3">
                            <input
                                class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                id="file_input" type="file">
                            <button
                                @click.stop.prevent="restore()"
                                class="mt-2 w-full flex flex-row justify-center items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-auto">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white mr-2 rotate-180 -scale-y-100" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                    viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M19 10V4a1 1 0 0 0-1-1H9.914a1 1 0 0 0-.707.293L5.293 7.207A1 1 0 0 0 5 7.914V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2M10 3v4a1 1 0 0 1-1 1H5m5 6h9m0 0-2-2m2 2-2 2" />
                                </svg>
                                Import
                            </button>
                        </div>
                    </div>
                </div>
                <div class="relative overflow-x-auto p-4 w-full" v-if="current_category == 'API Keys'">
                    <ul
                        class="hidden text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex dark:divide-gray-700 dark:text-gray-400 mb-4">
                        <li class="w-full">
                            <div class="inline-block cursor-pointer w-full px-4 py-2 rounded-l-lg bg-white border-r border-gray-200 dark:border-gray-700 hover:text-gray-700 hover:bg-gray-50 dark:hover:text-white "
                                :class="{ 'dark:bg-gray-700 text-white': current_api_tab == 'openai', 'dark:bg-gray-900 dark:bg-opacity-50': current_api_tab != 'openai' }"
                                aria-current="page" @click="current_api_tab = 'openai'">OpenAI</div>
                        </li>
                        <li class="w-full">
                            <div @click="current_api_tab = 'groq'"
                                class="inline-block cursor-pointer w-full px-4 py-2 bg-white border-r  border-gray-200 dark:border-gray-700 hover:text-gray-700 hover:bg-gray-50 dark:hover:text-white"
                                :class="{ 'dark:bg-gray-700 text-white': current_api_tab == 'groq', 'dark:bg-gray-900 dark:bg-opacity-50': current_api_tab != 'groq' }">
                                Groq</div>
                        </li>
                        <li class="w-full">
                            <div @click="current_api_tab = 'custom'"
                                class="inline-block cursor-pointer w-full px-4 py-2 rounded-r-lg   bg-white border-gray-200 dark:border-gray-700 hover:text-gray-700 hover:bg-gray-50 dark:hover:text-white"
                                :class="{ 'dark:bg-gray-700 text-white': current_api_tab == 'custom', 'dark:bg-gray-900 dark:bg-opacity-50': current_api_tab != 'custom' }">
                                Custom</div>
                        </li>
                    </ul>
                    <div class="mb-5 w-full" v-if="current_api_tab == 'openai'">
                        <label for="openai_key" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                            Key</label>
                        <input type="text" id="openai_key"
                            class="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            v-model="openai_key">
                        <label for="openai_url"
                            class="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                            URL</label>
                        <input type="text" id="openai_url"
                            class="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            v-model="openai_url">
                    </div>
                    <div class="mb-5 w-full" v-if="current_api_tab == 'custom'">
                        <label for="custom_key" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                            Key</label>
                        <input type="text" id="custom_key"
                            class="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            v-model="custom_key">
                        <label for="custom_url"
                            class="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                            URL</label>
                        <input type="text" id="custom_url"
                            class="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            v-model="custom_url">
                    </div>
                    <div class="mb-5 w-full" v-if="current_api_tab == 'groq'">
                        <label for="groq_key" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                            Key</label>
                        <input type="text" id="groq_key"
                            class="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            v-model="groq_key">
                        <label for="groq_url"
                            class="block mt-2 mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">
                            URL</label>
                        <input type="text" id="groq_url"
                            class="w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
                            v-model="groq_url">
                    </div>
                </div>
            </div>

        </template>
        <template v-slot:footer>
            <div class="flex flex-row justify-end inline-flex shadow-sm flex-grow" role="group">
                <button type="button" @click="closeModal"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    Cancel
                </button>
                <button type="button" @click="validate"
                    class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 text-center rounded-tl-none rounded-bl-none flex flex-row items-center">
                    <div class="me-3">Save</div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-floppy-fill" viewBox="0 0 16 16">
                        <path
                            d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z" />
                        <path
                            d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z" />
                    </svg>
                </button>
            </div>
        </template>
    </ModalComponent>
</template>
<script>
import ModalComponent from '@/components/modal.vue';
export default {
    components: {
        ModalComponent
    },
    name: 'SettingsModal',
    props: {
        title: {
            type: String,
            default: 'Settings',
        },
        isVisible: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            showModal: false,
            categories: [
                {
                    name: 'Pricings',
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0"/>
  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"/>
  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z"/>
  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"/>
</svg>`,
                    link: '/settings/pricings'
                },
                {
                    name: 'API Keys',
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-key-fill" viewBox="0 0 16 16">
  <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
</svg>`,
                    link: '/settings/api-keys'
                },
                {
                    name: "Backup & Restore",
                    icon: `<svg class="w-[16px] h-[16px] text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 10V4a1 1 0 0 0-1-1H9.914a1 1 0 0 0-.707.293L5.293 7.207A1 1 0 0 0 5 7.914V20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2M10 3v4a1 1 0 0 1-1 1H5m5 6h9m0 0-2-2m2 2-2 2"/>
</svg>
`,
                    link: '/settings/backups-and-restore'
                }
            ],
            current_category: 'Pricings',
            prices: [
                { model: 'gpt-3.5-turbo-1106', input: 0.001, output: 0.002 },
                { model: 'gpt-4', input: 0.03, output: 0.06 },
                { model: 'gpt-4-1106-preview', input: 0.01, output: 0.03 },
                { model: 'gpt-4-0125-preview', input: 0.01, output: 0.03 },
                { model: 'gpt-4-1106-vision-preview', input: 0.01, output: 0.03 },
                { model: 'mixtral', input: 0.0, output: 0.0 },
            ],
            current_api_tab: 'openai',
            openai_key: '',
            custom_key: '',
            groq_key: '',
            openai_url: '',
            custom_url: '',
            groq_url: '',
            backup_filename: '',
            restore_filename: ''
        };
    },
    methods: {
        validate() {
            this.$emit('update:confirmed', {
                prices: this.prices,
                openai_key: this.openai_key,
                custom_key: this.custom_key,
                groq_key: this.groq_key,
                openai_url: this.openai_url,
                custom_url: this.custom_url,
                groq_url: this.groq_url
            });
            this.closeModal();
        },
        closeModal() {
            this.$emit('update:isVisible', false);
        },
        handleVisibility(val) {
            this.$emit('update:isVisible', val);
        },
        backup() {
            this.$store.dispatch('backup', {
                filename: this.backup_filename,
            });
        },
        restore() {
            this.$store.dispatch('restore', {
                filename: 'backup.json'
            });
        }
    },
    computed: {
        settings_computed() {
            return this.$store.state.settings
        }
    },
    watch: {
        settings_computed(val) {
            this.prices = val.prices
            this.openai_key = val.openai_key
            this.custom_key = val.custom_key
            this.groq_key = val.groq_key
            this.openai_url = val.openai_url
            this.custom_url = val.custom_url
            this.groq_url = val.groq_url
        }
    },
}
</script>