<template>
    <transition-group name="toast" tag="div" class="absolute z-[99999] bottom-4 right-4">
        <div v-for="toast in toasts" :key="toast.id" 
             :id="'toast-' + toast.id"
             class="toast-item flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg  dark:text-gray-400 dark:bg-gray-700 shadow-md shadow-gray-900"
             role="alert">
            <div class="icon-container" :class="iconClasses(toast.type)">
                <span v-if="toast.type == 'success'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-check2-circle" viewBox="0 0 16 16">
                        <path
                            d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                        <path
                            d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                    </svg>
                </span>
                <span v-if="toast.type == 'danger'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-x-octagon" viewBox="0 0 16 16">
                        <path
                            d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z" />
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg>
                </span>
                <span v-if="toast.type == 'warning'">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-exclamation-triangle" viewBox="0 0 16 16">
                        <path
                            d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z" />
                        <path
                            d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                    </svg>
                </span>
            </div>
            <div class="toast-message ms-3 me-3 text-sm font-normal">{{ toast.text }}</div>
            <button @click="removeToast(toast.id)" class="close-button">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                </svg>
            </button>
        </div>
    </transition-group>
</template>
<script>
export default {
    name: 'Toasts',
    data() {
        return {
            toasts: []
        };
    },
    mounted() {
        this.toasts = [...this.$store.state.toasts];
    },
    watch: {
        '$store.state.toasts': {
            handler(newToasts) {
                this.handleToasts(newToasts);
            },
            deep: true
        }
    },
    methods: {
        handleToasts(newToasts) {
            this.toasts = [...newToasts].reverse();
            this.toasts.forEach(toast => {
                if (toast.timeout) {
                    setTimeout(() => {
                        this.removeToast(toast.id);
                    }, toast.timeout);
                }
            });
        },
        removeToast(id) {
            this.$store.commit('removeToast', id);
        },
        iconClasses(type) {
            return {
                'bg-green-100 dark:bg-green-800 dark:text-green-200': type === 'success',
                'bg-red-100 dark:bg-red-800 dark:text-red-200': type === 'danger',
                'bg-orange-100 dark:bg-orange-700 dark:text-orange-200': type === 'warning'
            };
        }
    }
}
</script>
<style scoped>
.toast-item {
    transition: all 0.3s ease-in-out;
}
.toast-enter-active, .toast-leave-active {
    transition: opacity 0.5s, transform 0.5s;
}
.toast-enter, .toast-leave-to {
    opacity: 0;
    transform: translateY(20px);
}
</style>

