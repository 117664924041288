<template>
    <section class="bg-gray-50 dark:bg-gray-900">
        <div class="flex flex-col w-full relative overflow-y-hidden">
            <div class="gradient-overlay-top h-[50px] z-20" style="top: 10px!important"></div>
            <div class="gradient-overlay-bottom h-[150px] z-20"></div>
            <div class="steps absolute top-6 bottom-4 left-4 w-[20%] overflow-y-auto overflow-x-hidden dark:text-gray-400 dark:bg-gray-800 pb-6 pt-3 rounded-md z-30"
                v-if="Object.keys(steps).length > 0">
                <h5 class="text-lg text-center mb-3 uppercase font-bold dark:text-gray-600">Overview</h5>
                <div class="space-y-4 text-gray-500 list-decimal list-inside dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:bg-opacity-30 mx-4 rounded-md"
                    v-for="(step, key, index) in steps" :key="step.key">
                    <div class="m-4 py-1">
                        <div class="step-title font-medium flex flex-row items-center">
                            <div
                                class="me-2 dark:text-gray-400 dark:bg-gray-700 dark:bg-opacity-40 rounded-full text-center w-6 h-6 text-sm pt-0.5">
                                {{ index + 1 }}</div>
                            <div class="capitalize ms-1 font-medium text-sm">{{ formatStepName(step.name) }}</div>
                        </div>
                        <div class="step-content dark:text-gray-500 text-sm ms-9" v-html="step.text"></div>
                    </div>
                </div>
            </div>
            <div class="flex messages  lg:px-[25%] flex-col w-full pb-36 mt-2.5 pt-6 w-full h-[calc(100vh-50px)] overflow-y-auto overflow-x-hidden"
                ref="messages">
                <div class="absolute bottom-[120px] left-[25%] right-[25%] cursor-pointer z-[100]"
                    @click="seeMoreMessages()" v-if="!autoscroll">
                    <div
                        class="flex flex-row items-center text-center w-full justify-center dark:bg-gray-800 dark:bg-opacity-50 border dark:border-gray-800 backdrop-blur-md mr-8 rounded-lg dark:hover:bg-gray-700/50 py-3">
                        <div
                            class="date-container date-container-small mr-4 dark:text-gray-400 text-sm flex-initial flex justify-center dark:hover:text-gray-400 transition font-bold flex flex-col items-center">
                            <div class="border dark:border-gray-100 rounded-full">
                                <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                    viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2" d="M12 19V5m0 14-4-4m4 4 4-4" />
                                </svg>

                            </div>
                        </div>
                    </div>
                </div>
                <div v-for="(message, index) in messages" :key="index">
                    <div class="message flex flex-row relative"
                        :class="{ 'mt-8': index == 0 || messages[index - 1].type != message.type }">
                        <div v-show="suggestions_loading && message.type == 'bot'"
                            :id="'suggestions-loading-' + message.message_id"
                            class="hidden absolute flex flex-row justify-center bottom-0 left-0 right-0 h-32 transform translate-y-full w-full basis-6/6 px-4 mt-2 message-content z-50">
                            <div role="status" class="mt-4 animate-fade">
                                <svg aria-hidden="true"
                                    class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor" />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill" />
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="flex basis-1 min-w-[36px]">
                            <div class="w-[36px] h-[36px] rounded-full flex flex-col items-center dark:bg-gray-800"
                                v-if="index === 0 || messages[index - 1]?.type !== message.type">
                                <div class="flex flex-row items-center justify-center h-full w-full dark:text-gray-500">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-person-fill scale-125" viewBox="0 0 16 16"
                                        v-if="message.type == 'user'">
                                        <path
                                            d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        v-else class="bi bi-robot scale-125" viewBox="0 0 16 16">
                                        <path
                                            d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.6 26.6 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.93.93 0 0 1-.765.935c-.845.147-2.34.346-4.235.346s-3.39-.2-4.235-.346A.93.93 0 0 1 3 9.219zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a25 25 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25 25 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135" />
                                        <path
                                            d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2zM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="basis-6/6 px-4 mt-2 message-content subpixelantialiased dark:text-gray-400 w-full"
                            v-if="message.type == 'bot' && message.suggestions">
                            <div class="grid grid-cols-2 gap-1.5 w-full">
                                <div v-for="(suggestion, idx) in message.suggestions"
                                    :key="message.message_id + '_' + idx"
                                    :id="'suggestion_' + message.message_id + '_' + idx"
                                    class="dark:text-gray-500 opacity-0 text-sm dark:hover:text-gray-400 dark:hover:text-gray-200 hover:cursor-pointer dark:hover:bg-gray-700 dark:bg-gray-800 p-2 rounded-lg text-m"
                                    :class="{ 'animate-slidein300': idx == 1, 'animate-slidein500': idx == 2, 'animate-slidein700': idx == 3 }"
                                    @click="useSuggestion(suggestion, message.message_id + '_' + idx)">
                                    {{ suggestion }}
                                </div>
                            </div>
                        </div>
                        <div class="basis-6/6 px-4 pt-0.5 message-content subpixelantialiased dark:text-gray-400"
                            v-else-if="message.text">
                            <div class="font-bold" v-if="index === 0 || messages[index - 1]?.type !== message.type">
                                {{ message.type == 'user' ? 'Vous' : 'AI' }}
                            </div>
                            <p v-html="message.text" :class="{ 'dark:text-green-400': message.status == 'success' }">
                            </p>
                        </div>
                    </div>
                </div>
                <div class="message flex flex-row ps-2 pt-6 " v-if="loading">
                    <div class="flex basis-1 min-w-[36px]"></div>
                    <div class='flex space-x-2 justify-center items-center'>
                        <span class='sr-only'>Loading...</span>
                        <div class='h-2 w-2 dark:bg-gray-600 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
                        <div class='h-2 w-2 dark:bg-gray-600 rounded-full animate-bounce [animation-delay:-0.15s]'>
                        </div>
                        <div class='h-2 w-2 dark:bg-gray-600 rounded-full animate-bounce [animation-delay:-0.05s]'>
                        </div>
                    </div>
                </div>
                <div class="message flex flex-row ps-2 pt-6" v-if="solver_results">
                    <div class="flex basis-1 min-w-[36px]"></div>
                    <div class='flex grid grid-cols-3 gap-2 justify-center items-center w-full mx-8'>
                        <div class="solver_result flex dark:bg-gray-800 p-2 rounded-md"
                            v-for="(profile, profile_index) in solver_results" :key="profile_index">
                            <div class="flex flex-col items-center w-full">
                                <div
                                    class="w-20 h-20 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center mb-2">
                                    <img :src="'https://placeholderimage.eu/api/id/' + profile_index"
                                        class="w-20 h-20 rounded-full" alt="Profile Picture" />
                                </div>
                                <div class="flex flex-col items-center text-center">
                                    <div class="font-bold dark:text-gray-400 mb-1">{{ profile[0].name }}</div>
                                    <div class="text-sm dark:text-gray-500 mb-1 line-clamp-2">{{ profile[0].skills }}
                                    </div>
                                    <div class="text-sm dark:text-gray-500 mb-1 font-bold">{{ profile[0].experience }}
                                        of experience</div>
                                    <div class="text-sm dark:text-green-400">{{ (parseFloat(profile[1]) *
                                        100).toFixed(0)
                                        + '% match' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="query_input absolute bottom-4 left-4 lg:left-[25%] right-4 lg:right-[25%] z-50">
                <div class="suggestions">
                    <div v-for="suggestion in default_suggestions"
                        class="dark:text-gray-500 dark:text-opacity-70 text-sm mb-2 dark:hover:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:bg-opacity-50 dark:hover:bg-opacity-30 dark:active:bg-gray-800 dark:active:bg-opacity-30 hover:cursor-pointer inline-flex px-2 rounded-full me-1 dark:active:text-gray-500"
                        @click="useSuggestion(suggestion)" ref="suggestion">{{ suggestion }}</div>
                </div>
                <div class="relative">
                    <div class="absolute inset-y-0 start-0 flex items-center ps-3 cursor-pointer"
                        @click.stop.prevent="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-paperclip dark:text-gray-400 scale-[140%]" viewBox="0 0 16 16">
                            <path
                                d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0z" />
                        </svg>
                    </div>
                    <input type="search" id="default-search" v-model="query"
                        @keyup.enter="debounce(() => submitQuery(), 1000)"
                        class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Ask a question..." required />
                    <button type="submit" @click.stop.prevent="debounce(() => submitQuery(), 1000)" :disabled="loading"
                        class="absolute end-2.5 bottom-2.5 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:outline-none font-medium rounded-lg text-sm px-2.5 py-2.5 text-center  flex flex-row"
                        :class="{
                            'active:bg-gradient-to-l': !loading,
                        }">
                        <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" v-if="loading"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            viewBox="0 0 24 24">
                            <path d="M7 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H7Z" />
                        </svg>

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" v-else
                            class="bi bi-send" viewBox="0 0 16 16">
                            <path
                                d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'Solver',
    data() {
        return {
            query: '',
            messages: [],
            first_question: true,
            next_question: {},
            default_suggestions: [
                'How to make a paper plane ?',
                'Optimize my business process',
                'How to make a good coffee ?',
                'How to write a book ?',
            ],
            autoscroll: true,
            loading: false,
            session_id: null,
            state_key: null,
            current_message_id: null,
            max_messages: 100,
            steps: {},
            solver_results: [],
            suggestions_loading: false
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.$refs.messages.addEventListener('wheel', () => {
                this.$nextTick(() => {
                    if (this.$refs.messages.scrollTop > 0)
                        this.autoscroll = false;
                });
            });
        });
    },
    methods: {
        seeMoreMessages() {
            this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
            this.autoscroll = true;
        },
        scrollToBottom: _.throttle(function () {
            const element = this.$refs.messages;
            if (!element) return;
            if (this.autoscroll)
                element.scrollTop = element.scrollHeight;
        }, 180),
        debounceScroll() {
            if (this.autoscroll) {
                this.scrollToBottom()
            }
        },
        debounce(func, wait, immediate) {
            let timeout;
            return function () {
                const context = this,
                    args = arguments;
                const later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                const callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },
        async submitQuery() {
            if (this.query.length > 0 && !this.session_id) {
                this.loading = true;
                const query = this.query;
                this.query = '';
                let key = "initial_question"
                this.steps[key] = { text: query, key: key, name: key };
                this.messages.push({ text: query, type: 'user' });

                this.$nextTick(() => {
                    this.scrollToBottom();
                });

                let response = await this.$store.dispatch('solveQuery', { query: query });

                if (response?.status == 'queued') {
                    this.session_id = response.session_id;
                }
            }
            else if (this.session_id) {
                this.loading = true;
                this.messages.push({ text: this.query, type: 'user' });
                this.$socket.emit('user_response', {
                    session_id: this.session_id,
                    query: this.query,
                    key: this.state_key
                });
                this.steps[this.state_key] = { text: this.query, key: this.state_key, name: this.state_key };
                this.query = '';
                this.$nextTick(() => {
                    this.scrollToBottom();
                });
            }
        },
        formatStepName(name) {
            return name.replace(/_/gi, ' ').toLowerCase();
        },
        useSuggestion(suggestion, ref = '') {
            this.$nextTick(() => {
                this.query = suggestion;
                this.debounce(() => {
                    this.submitQuery();
                }, 200)();

                if (ref.length == 0)
                    return;

                let el = document.getElementById('suggestion_' + ref);

                if (el) {
                    el.classList.remove('dark:bg-gray-800');
                    el.classList.remove('dark:hover:bg-gray-700');
                    el.classList.remove('dark:text-gray-500');
                    el.classList.add('bg-gradient-to-br', 'from-blue-500', 'via-blue-600', 'to-blue-700', 'transition');
                    el.classList.add('dark:text-white', 'dark:hover:text-white');
                }
            })
        }
    },
    sockets: {
        suggested_questions(data) {
            // this.messages.push({ text: data.next_question, type: 'bot' });
            this.messages.push({ suggestions: data.suggestions, type: 'bot', message_id: data.message_id });

            this.$nextTick(() => {
                let el = document.getElementById('suggestions-loading-' + this.current_message_id);

                if (el) {
                    el.remove();
                }
                const delays = [];
                for (let i = 0; i < data.suggestions.length; i++) {
                    delays.push(200 + i * 200);
                }
                delays.forEach((delay, index) => {
                    let suggestion = document.getElementById('suggestion_' + data.message_id + '_' + index);
                    if (suggestion) {
                        setTimeout(() => {
                            suggestion.classList.remove('opacity-0');
                            suggestion.classList.add('opacity-100');
                        }, delays[index]);
                    }
                });

                this.scrollToBottom();
                this.loading = false;
            });
        },
        solver_question(data) {
            this.state_key = data.key;
            this.messages.push({ text: data.question, type: 'bot' });
            this.$nextTick(() => {
                this.scrollToBottom();
                this.loading = false;
            });
        },
        solver_question_chunk(data) {
            this.state_key = data.key;

            if (this.current_message_id != data.message_id) {
                this.messages.push({
                    message_id: data.message_id,
                    time: data.time,
                    text: data.chunk,
                    type: 'bot',
                    isComplete: false,
                });

                this.current_message_id = data.message_id;

                this.$nextTick(() => {
                    this.scrollToBottom();


                });
            } else {
                let lastMessage = this.messages[this.messages.length - 1];

                if (lastMessage) {
                    lastMessage.text += data.chunk;
                    this.$nextTick(() => {
                        this.scrollToBottom();
                    });
                }
            }

            if (this.messages.length > this.max_messages)
                this.messages.shift();

            this.loading = false;
        },
        solver_completed(data) {
            this.messages.push({ text: 'Gathering information about the problem complete', type: 'bot', status: 'success' });
            this.session_id = null;


            this.$nextTick(() => {
                this.scrollToBottom();
                this.loading = false;
            });
        },
        solver_results(data) {
            this.solver_results = data.profiles;
            this.messages.push({ text: 'Recommending profiles...', type: 'bot', status: 'info', message_id: data.message_id });
            this.session_id = null;
            this.$nextTick(() => {
                this.scrollToBottom();
                this.loading = false;
            });
        },
        suggestions_loading() {
            this.$nextTick(() => {
                this.suggestions_loading = true;

                let el = document.getElementById('suggestions-loading-' + this.current_message_id);

                if (el) {
                    el.classList.remove('hidden');
                }
            });
        }
    }
}
</script>
<style scoped>
.messages {
    scroll-behavior: smooth;
}
</style>