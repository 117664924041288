<template>
    <div v-if="isVisible" tabindex="-1" aria-hidden="true" ref="modal" data-modal-placement="center-center"
        class="fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div class="relative p-0 w-full max-w-2xl max-h-full m-auto" ref="modalContent">
            <div class="relative bg-white rounded-lg shadow-lg shadow-gray-950/50 dark:bg-gray-700">
                <div class="flex items-center justify-between p-1 md:p-2 border-b rounded-t dark:border-gray-600">
                    <h3 class="text-lg px-4 font-semibold text-gray-900 dark:text-gray-400">
                        <slot name="title">{{ title }}</slot>
                    </h3>
                    <button type="button" @click="closeModal"
                        class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-x-lg" viewBox="0 0 16 16">
                            <path
                                d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <div class="space-y-4 dark:bg-gray-800">
                    <slot name="body"></slot>
                </div>
                <div
                    class="flex items-center p-2 md:p-3 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <slot name="footer"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'ModalComponent',
    props: {
        title: {
            type: String,
            default: 'Modal Title',
        },
        isVisible: {
            type: Boolean,
            required: true
        },
    },
    mounted() {
        document.addEventListener('keydown', this.handleEscapeKey);
    },
    methods: {
        closeModal() {
            this.$emit('update:isVisible', false);
        },
        handleOverlayClick(event) {
            if (this.$refs.modalContent && !this.$refs.modalContent.contains(event.target)) {
                this.closeModal();
            }
        },
        handleEscapeKey(event) {
            if (event.key === 'Escape' && this.isVisible) {
                this.closeModal();
            }
        }
    },
};
</script>
