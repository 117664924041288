<template>
    <div class="dark:text-gray-500 flex-col messages relative lg:px-96 " style="height:calc(100vh - 223px)">
        <div class="absolute top-0 left-1 w-[380px] bottom-0 rounded-md  max-h-[100%] overflow-x-hidden hidden md:block"
            v-show="$store.state.show_overview_panel" style="will-change: transform">
            <div class="gradient-overlay-top h-[25px] z-30"></div>
            <div class="gradient-overlay-bottom h-[50px] z-30"></div>
            <div class="p-2 relative h-full overflow-y-auto">
                <Output :run="run" :chat-id="$route.params.chatId"
                    :auto-expand="$store.state.real_time_overview"></Output>
            </div>
        </div>
        <div class="absolute top-2 right-2 w-[370px] bottom-1.5 rounded-md max-h-[100%] overflow-x-hidden overflow-y-hidden hidden md:block"
            v-show="$store.state.show_sources_panel" style="will-change: transform">
            <div class="p-2 relative h-full">
                <div class="gradient-overlay-top h-[25px] z-30"></div>
                <div class="gradient-overlay-bottom h-[50px] z-30"></div>
                <div class="flex flex-col h-full relative">
                    <div class="gradient-overlay-top h-[25px] z-10"></div>
                    <div class="gradient-overlay-bottom h-[50px] z-30"></div>
                    <div class="flex flex-col h-full overflow-y-auto relative pb-2 pt-2 pe-4">
                        <a :href="source.url" :title="source.url" target="_blank" v-for="source in sources"
                            :key="source.id"
                            class="flex flex-col dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:bg-opacity-80 mb-1 p-2 rounded-lg">
                            <div class="flex flex-row items-center">
                                <div
                                    class="text-xs dark:text-gray-400 break-all line-clamp-2 font-semibold subpixel-antialiased">
                                    {{ source.text }}</div>
                            </div>
                            <div class="flex flex-row items-center mt-2">
                                <img :src="getFaviconUrl(source.url)" class="mr-1.5 rounded-full" />
                                <div
                                    class="flex-initial text-xs dark:text-gray-500 truncate font-medium subpixel-antialiased">
                                    {{
                                        formatSourceUrl(source.url)
                                    }}</div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative h-full w-full">
            <div class="gradient-overlay-top h-[50px] z-30"></div>
            <div class="gradient-overlay-bottom h-[50px] z-30"></div>
            <div class="absolute bottom-3 left-4 right-6 cursor-pointer z-50" @click="seeMoreMessages()"
                v-if="!autoscroll">
                <div
                    class="flex flex-row items-center text-center w-full justify-center dark:bg-gray-800 dark:bg-opacity-40 border dark:border-gray-800 backdrop-blur-md mr-8 rounded-lg dark:hover:bg-gray-700/50">
                    <div
                        class="date-container date-container-small mr-4 dark:text-gray-600 text-sm flex-initial flex justify-center dark:hover:text-gray-400 transition font-bold">
                        See more messages</div>
                </div>
            </div>
            <div class="logs-messages overflow-y-auto max-h-[100%] overflow-x-hidden" ref="messages">
                <div class="flex flex-col" v-for="message in messages_formatted" :key="message.message_id">
                    <div class="date-container mr-4 dark:text-gray-700 text-sm flex-initial flex justify-center">
                        <span class="date mx-3 font-mono">{{ formatTimestamp(message.time) }}</span>
                    </div>
                    <div class="flex flex-row items-center">
                        <svg class="w-6 h-6 text-gray-800 dark:text-blue-500 dark:text-opacity-70 me-2"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" v-if="message.type == 'info'"
                            width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M10 11h2v5m-2 0h4m-2.592-8.5h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <svg class="w-6 h-6 text-gray-800 dark:text-green-500 dark:text-opacity-70 me-2"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" v-if="message.type == 'success'"
                            width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M8.5 11.5 11 14l4-4m6 2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <svg class="w-6 h-6 text-gray-800 dark:text-yellow-500 dark:text-opacity-70 me-2"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg" v-if="message.type == 'warning'"
                            width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        <div class="flex-initial w-full text-sm break-words subpixel-antialiased relative preview-container"
                            :class="{
                                'dark:text-red-500 font-mono ': message.type == 'error',
                                'dark:text-green-500 font-mono ': message.type == 'success',
                                'dark:text-yellow-500 font-mono ': message.type == 'warning',
                                'dark:text-blue-500 font-mono ': message.type == 'info',
                                'dark:text-gray-500': message.type == 'debug',
                            }">

                            <div class="log-message subpixelantialiased" v-html="message.message" :id="'message_' + message.message_id">
                            </div>

                            <div class="absolute cursor-pointer rounded-bl-lg rounded-tr-lg px-2 py-0.5 top-0 right-0 preview_copy dark:hover:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:border-opacity-20"
                                :class="{ ' dark:bg-green-500 dark:text-white': copied, ' dark:bg-gray-50 dark:text-gray-400 ': !copied }"
                                @click="copyToClipboard('message_' + message.message_id)">
                                <div class="flex flex-row items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-clipboard" v-if="!copied"
                                        :class="{ ' dark:text-white ': copied, ' dark:text-gray-400 ': !copied }"
                                        viewBox="0 0 16 16">
                                        <path
                                            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                        <path
                                            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        v-if="copied"
                                        :class="{ ' dark:text-white ': copied, ' dark:text-gray-400 ': !copied }"
                                        class="bi bi-clipboard-check" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                        <path
                                            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                                        <path
                                            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                                    </svg>
                                    <div class="ml-2 text-xs">{{ copy_text }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TimeMixin from '@/mixins/time.js'
import { marked } from 'marked';
import Output from '@/components/output.vue';
import _ from 'lodash';

export default {
    mixins: [TimeMixin],
    components: {
        Output
    },
    props: {
        chatId: {
            type: String,
            required: true
        },
        runId: {
            type: String,
            required: true
        },
        run: {
            type: Object,
            default: () => ({})
        },
    },
    data() {
        return {
            isAtBottom: true,
            messages: [],
            current_message_id: null,
            new_tokens: 0,
            autoscroll: true,
            sources: [],
            copied: false,
            copy_text: 'Copy',
            max_messages: 100,
        }
    },
    mounted() {
        this.$store.dispatch('setRunLogs', {
            chatId: this.chatId,
            runId: this.runId
        });

        this.$nextTick(() => {
            this.$refs.messages.addEventListener('wheel', () => {
                this.$nextTick(() => {
                    if (this.$refs.messages.scrollTop > 0)
                        this.autoscroll = false;
                });
            });
        });
    },
    methods: {
        scrollToBottom: _.throttle(function () {
            const element = this.$refs.messages;
            if (!element) return;
            if (this.autoscroll)
                    element.scrollTop = element.scrollHeight;
        }, 300),
        debounceScroll() {
            if (this.autoscroll) {
                this.scrollToBottom()
            }
        },
        getFaviconUrl(url) {
            return `https://www.google.com/s2/favicons?domain=${url}`;
        },
        formatSourceUrl(url) {
            try {
                return new URL(url).hostname.replace('www.', '');
            }
            catch (e) {
                return url;
            }
        },
        seeMoreMessages() {
            this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
            setTimeout(() => {
                this.autoscroll = true;
            }, 600);
        },
        copyToClipboard(elementId) {
            const el = document.getElementById(elementId);
            const range = document.createRange();
            range.selectNodeContents(el);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            document.execCommand('copy');
            sel.removeAllRanges();
            this.copied = true;
            this.copy_text = 'Copied !';

            setTimeout(() => {
                this.copied = false;
                this.copy_text = 'Copy';
            }, 700);
        },
    },
    watch: {
        logs(data) {
            this.messages = data;
        },
        'run.links': {
            handler(data) {
                this.sources = data;
            },
            deep: true
        },
        '$store.state.clearing_logs': {
            handler(data) {
                if (data) {
                    this.messages = [];
                    this.$store.dispatch('clearLogs', false);
                }
            }, deep: true
        }
    },
    computed: {
        messages_formatted() {
            let messages = [];

            for (let m of this.messages) {
                if (m == null) continue;
                let message = {
                    time: m.time,
                    type: m.type,
                    message: m.type == 'debug' ? marked.parse(m.message) : m.message,
                    message_id: m.message_id,
                    isComplete: m.isComplete,
                };
                messages.push(message);
            }

            return messages;
        },
    },
    sockets: {
        run_links(data) {
            for (let link of data.links)
                this.sources.push(link);
        },
        run_log(data) {
            if (this.messages.length == 0) return;
            if (this.messages.map(message => message.message_id).includes(data.message_id))
                return;

            this.messages.push(data);

            this.debounceScroll();
        },
        response_complete(data) {
            this.$store.dispatch('setRunTotalResponseTokens', {
                runId: data.run_id,
                total_responses_tokens: data.total_response_tokens
            });
        },
        response_update(data) {
            this.$store.dispatch('setRunTotalResponseTokens', {
                runId: data.run_id,
                total_responses_tokens: data.total_response_tokens
            });

            if (this.current_message_id != data.message_id) {
                this.messages.push({
                    time: data.time,
                    message: data.chunk,
                    type: 'debug',
                    isComplete: false,
                });
                this.current_message_id = data.message_id;

            } else {
                let lastMessage = this.messages[this.messages.length - 1];

                if (lastMessage)
                    lastMessage.message += data.chunk;
            }

            if (this.messages.length > this.max_messages)
                this.messages.shift();

            this.$nextTick(() => {
                this.debounceScroll();
            });
        },
    },
}
</script>
<style scoped>
.date-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.date-container::before,
.date-container::after {
    content: '';
    flex: 1;
    margin: 40px 0 40px 0;
    height: 2px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1) 16.3333%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 16px 2px;
    background-repeat: repeat-x;
    border-radius: 100%;
}

.date-container-small::before,
.date-container-small::after {
    margin: 20px 0 !important;
}

.date {
    white-space: nowrap;
}

.logs-messages {
    scroll-behavior: smooth;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}



.preview_copy {
    display: none;
    transition: all .2s;
}

.preview-container:hover .preview_copy {
    display: block;
}

.log-message {
    padding: 1rem;
    border: 1px solid transparent;
}

.log-message:hover {
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 8px;
}

div p:last-child {
    margin-bottom: 0!important;
}
</style>