import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/home.vue'
import Monitor from '@/views/monitor.vue'
import MonitorRun from '@/views/monitor_run.vue'
import Variables from '@/views/variables.vue'
import Prompts from '@/views/prompts.vue'
import Solver from '@/views/solver.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/solver',
    name: 'solver',
    component: Solver
  },
  {
    path: '/:chatId/monitor/:runId',
    name: 'monitor_run',
    component: MonitorRun
  },
  {
    path: '/:chatId/monitor',
    name: 'monitor',
    component: Monitor,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/:chatId/variables',
    name: 'variables',
    component: Variables,
    meta: { transition: 'slide-left' },
  },
  {
    path: '/:chatId/prompts',
    name: 'prompts',
    component: Prompts,
    meta: { transition: 'slide-left' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
