<template>
    <ModalComponent :title="title" :isVisible="isVisible" @update:isVisible="handleVisibility">
        <template v-slot:body>
            <form class="w-full p-4" @submit.prevent="validate">
                <div>
                    <label for="document_name"
                        class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400">Name</label>
                    <input type="text" id="document_name" ref="document_name" v-model="run_obj.name"
                        class="bg-gray-50 border border-gray-300 dark:border-opacity-70 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required placeholder="Document name">
                </div>
                <div class="mt-3">
                    <label class="inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" class="sr-only peer" v-model="include_sequence_descriptions">
                        <div
                            class="relative w-11 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                        </div>
                        <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-400">Include sequence
                            descriptions</span>
                    </label>

                </div>
                <div class="mt-3 flex flex-row w-full gap-3">
                    <div class="flex items-center ps-4 border rounded w-1/2 dark:bg-opacity-30 dark:hover:border-blue-500 dark:hover:bg-opacity-30" :class="{'dark:bg-blue-800 dark:border-blue-500' : is_pdf == 'on', 'dark:border-gray-700 dark:hover:bg-blue-800' : is_pdf == 'off'}">
                        <input id="bordered-radio-1" type="radio" value="on" name="bordered-radio" v-model="is_pdf"
                            class="w-4 h-4 text-blue-600 cursor-pointer bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="bordered-radio-1"
                            class="w-full cursor-pointer py-4 ms-2 text-sm font-bold text-gray-900 dark:text-gray-300 text-right pe-3">.docx</label>
                    </div>
                    <div class="flex items-center ps-4 border rounded w-1/2 dark:bg-opacity-30 dark:hover:bg-blue-800 dark:hover:border-blue-500 dark:hover:bg-opacity-30" :class="{'dark:bg-blue-800 dark:border-blue-500 ' : is_pdf == 'off', 'dark:border-gray-700' : is_pdf == 'on'}">
                        <input id="bordered-radio-2" type="radio" value="off" name="bordered-radio" v-model="is_pdf" disabled
                            class="w-4 h-4 cursor-pointer text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                        <label for="bordered-radio-2"
                            class="w-full cursor-pointer py-4 ms-2 text-sm font-bold text-gray-900 dark:text-gray-300 text-right pe-3">.pdf</label>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <div class="flex flex-row justify-end inline-flex rounded-md shadow-sm flex-grow">
                <button type="button" @click="closeModal"
                    class="flex px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white flex flex-row items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg>
                    <div class="ms-2">Cancel</div>
                </button>
                <button type="button" @click="validate"
                    class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 text-center rounded-tl-none rounded-bl-none flex flex-row items-center">
                    <div class="me-3">Download</div>
                    <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                    </svg>
                </button>
            </div>
        </template>
    </ModalComponent>
</template>
<script>
import ModalComponent from '@/components/modal.vue';
export default {
    components: {
        ModalComponent
    },
    name: 'EditChatRunModal',
    props: {
        title: {
            type: String,
            default: 'Modal Title',
        },
        isVisible: {
            type: Boolean,
            required: true
        },
        run: {
            type: Object,
            required: true
        },
        creating: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            showModal: false,
            include_sequence_descriptions: false,
            is_pdf: 'on',
            run_obj: { ...this.run },
        };
    },
    methods: {
        validate() {
            if (this.run_obj.name.length == 0)
                this.run_obj.name = 'Untitled';

            this.downloadOutput();
            this.closeModal();
        },
        closeModal() {
            this.$emit('update:isVisible', false);
        },
        handleVisibility(val) {
            this.$emit('update:isVisible', val);
        },
        async downloadOutput() {
            let date = new Date();
            let pad = function (num) { return ('00' + num).slice(-2) };

            this.$nextTick(() => {
                this.$store.dispatch('downloadOutput', {
                    chatId: this.$route.params.chatId,
                    runId: this.$route.params.runId,
                    chat_name: this.$store.state.current_chat.metadata.name,
                    include_sequence_descriptions: this.include_sequence_descriptions,
                    is_pdf: this.is_pdf,
                    run_name: this.run.name,
                    current_date: date.getFullYear() + pad(date.getMonth() + 1) + pad(date.getDate()),
                });
            });
        },
    },
    watch: {
        run: {
            immediate: true,
            handler(newVal) {
                this.run_obj = {
                    name: newVal.name,
                    api: newVal.api,
                    model: newVal.model,
                    temperature: newVal.temperature,
                    dry_run: newVal.dry_run || true,
                    search_agent_enabled: newVal.search_agent_enabled || false,
                    search_agent_model: newVal.search_agent_model || 'mixtral',
                    search_agent_temperature: newVal.search_agent_temperature || 0.1,
                    search_agent_max_search_results: newVal.search_agent_max_search_results || 5,
                }
            }
        },
        showModal(val) {
            this.$emit('update:isVisible', val);
        },
        isVisible(val) {
            if (val && this.creating) {
                this.$nextTick(() => {
                    this.$refs.document_name.focus();
                });
            }
        }
    },
}
</script>
<style scoped>

</style>