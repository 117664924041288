import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-3-socket.io'
import './assets/css/styles.scss'
import VueHighlightJS from 'vue3-highlightjs'
import 'highlight.js/styles/stackoverflow-dark.css'

let api_base = 'http://localhost:5005';

if (process.env.NODE_ENV === 'production')
  api_base = process.env.VUE_APP_API_BASE;

const socketio = new VueSocketIO({
    connection: api_base,
    debug: false
});

createApp(App)
    .use(store)
    .use(socketio)
    .use(router)
    .use(VueHighlightJS)
    .mount('#app');
