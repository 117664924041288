<template>
    <div class="relative" :class="width_class + ' ' + height_class">
        <svg class="absolute top-0 left-0 transform -rotate-90" width="100%" height="100%" :viewBox="'0 0 ' + width + ' ' + height">
            <circle class="stroke-current text-gray-200 dark:text-gray-700" :cx="cx" :cy="cy" :r="radius" fill="none"
                :stroke-width="stroke_width" />
            <circle class="stroke-current text-blue-600 dark:text-blue-500" :cx="cx" :cy="cy" :r="radius" fill="none"
                :stroke-width="stroke_width" :stroke-dasharray="Math.PI * 2 * radius * (1 - (-progress / 100))"
                :stroke-dashoffset="Math.PI * 2 * radius" />
        </svg>
        <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <div class="dark:text-gray-400 font-semibold" :style="'font-size:' + (3.3333 * Math.log(radius)) +'px'">
                {{ progress }}&nbsp;%
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Gauge',
    props: {
        width_class: {
            type: String,
            default: 'w-10'
        },
        height_class: {
            type: String,
            default: 'h-10'
        },
        width: {
            type: Number,
            default: 42
        },
        height: {
            type: Number,
            default: 42
        },
        progress: {
            type: Number,
            default: 0
        },
        stroke_width: {
            type: Number,
            default: 2
        },
    },
    computed: {
        cx() {
            return this.width * 0.5;
        },
        cy() {
            return this.height * 0.5;
        },
        radius() {
            return this.width * 0.5 - this.stroke_width * 0.5;
        },
    }
}
</script>