<template>
  <Toasts />
  <Topbar />
  <Sidebar />
  <div class="flex flex-col w-full h-full pt-10">
    <div class="sm:ml-64 h-full static">
      <div class="border-b border-gray-200 dark:border-gray-700 pt-2" v-if="$route.params.chatId">
        <ul class="flex flex-wrap text-sm font-medium text-center lg:pt-0 justify-center lg:justify-normal">
          <li class="me-2" v-for="link in menu">
            <router-link :to="{ name: link.path, params: { chatId: $route.params.chatId } }"
              class="inline-block p-2 lg:p-4  rounded-t-lg flex flex-col lg:flex-row items-center dark:text-gray-400 dark:hover:text-white hover:text-gray-900"
              :class="{ 'border-blue-500 dark:border-blue-500 text-blue-500 dark:text-white border-b-2 ': $route.name == link.path }">
              <div v-html="link.icon"></div>
              {{ link.name }}
            </router-link>
          </li>
        </ul>
      </div>
      <router-view />
    </div>
  </div>
</template>
<script>
import Sidebar from '@/components/sidebar.vue'
import Topbar from '@/components/topbar.vue'
import Toasts from '@/components/toasts.vue'

export default {
  name: 'App',
  components: {
    Sidebar,
    Topbar,
    Toasts
  },
  data() {
    return {
      transitionName: 'slide-left',
      current_chat: null,
      menu: [
        {
          name: 'Variables',
          path: 'variables',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-sliders2-vertical mb-1 lg:mb-0 lg:mr-2" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M0 10.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1H3V1.5a.5.5 0 0 0-1 0V10H.5a.5.5 0 0 0-.5.5M2.5 12a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0v-2a.5.5 0 0 0-.5-.5m3-6.5A.5.5 0 0 0 6 6h1.5v8.5a.5.5 0 0 0 1 0V6H10a.5.5 0 0 0 0-1H6a.5.5 0 0 0-.5.5M8 1a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0v-2A.5.5 0 0 0 8 1m3 9.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1H14V1.5a.5.5 0 0 0-1 0V10h-1.5a.5.5 0 0 0-.5.5m2.5 1.5a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 1 0v-2a.5.5 0 0 0-.5-.5" />
              </svg>`,
        },
        {
          name: 'Prompts',
          path: 'prompts',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-terminal-split mb-1 lg:mb-0 lg:mr-2" viewBox="0 0 16 16">
                <path
                  d="M2.146 3.146a.5.5 0 0 1 .708 0l.823.824a.75.75 0 0 1 0 1.06l-.823.824a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708ZM4 6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1A.5.5 0 0 1 4 6m6.354-2.854a.5.5 0 0 0-.708.708l.647.646-.647.646a.5.5 0 1 0 .708.708l.823-.824a.75.75 0 0 0 0-1.06l-.823-.824ZM12 5.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" />
                <path
                  d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h5.5V2zm6.5 0v12H14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
              </svg>`,
        },
        {
          name: 'Monitor',
          path: 'monitor',
          icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-activity mb-1 lg:mb-0 lg:mr-2" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                  d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2" />
              </svg>`,
        },
      ]
    }
  },
  mounted() {
    this.current_chat = {
      _id: this.$route.params.chatId,
    }
    
    this.$store.dispatch('setSettings');
  },
  sockets: {
    run_prepare(data) {
      if (!this.current_chat) return;
      this.$store.dispatch('setRunPrepare', {
        runId: data.run_id,
        status: data.status,
      })
    },
    run_start(data) {
      if (!this.current_chat) return;
      this.$store.dispatch('setRunStart', {
        runId: data.run_id,
        start_time: data.start_time,
        status: data.status,
        total_prompts: data.total_prompts,
        total_prompts_tokens: data.total_prompts_tokens,
        total_tokens: data.total_tokens,
        sectionsCompleted: data.sectionsCompleted,
        section_index: 0,
        clip_index: 0,
        task_index: 0
      })
    },
    run_end(data) {
      if (!this.current_chat) return;
      this.$store.dispatch('setRunEnd', {
        runId: data.run_id,
        end_time: data.end_time,
        status: data.status,
        sectionsCompleted: data.sectionsCompleted,
      })
    },
    run_progress(data) {
      if (!this.current_chat) return;
      this.$store.dispatch('setRunProgress', {
        runId: data.run_id,
        progress: data.progress,
        status: data.status,
        current_prompt: data.current_prompt,
        total_responses_tokens: data.total_responses_tokens,
        sectionsCompleted: data.sectionsCompleted,
        section_index: data.section_index,
        clip_index: data.clip_index,
        task_index: data.task_index,
        isPrePrompt: data.isPrePrompt
      })
    },
   
  },
}
</script>