export default {
    name: 'TimeMixin',
    methods: {
        secondsToDuration(seconds) {
            const days = Math.floor(seconds / (24 * 3600));
            seconds -= days * (24 * 3600);
        
            const hours = Math.floor(seconds / 3600);
            seconds -= hours * 3600;
        
            const minutes = Math.floor(seconds / 60);
            seconds -= minutes * 60;
        
            return `${days > 0 ? days + "d&nbsp;" : ""}${hours > 0 ? hours + "h&nbsp;" : ""}${minutes > 0 ? minutes + "m&nbsp;" : ""}${seconds > 0 ? seconds + "s" : ""}`.trim();
        },
        formatTimestamp(timestamp) {
            const date = new Date(timestamp);
            const formattedDate = date.toLocaleDateString('en-US', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            const formattedTime = date.toLocaleTimeString('en-US', {
                hour12: false,
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });

            return `${formattedDate} at ${formattedTime}`;
        }
    }
}