<template>
    <div class="bg-gray-800 shadow relative text-gray-400">
        <div class="absolute uppercase top-1 z-10" :class="get_run_status_class(run)"
            style="left: calc(50% - 35px); width: 70px; text-align: center;">
            {{ run.status }}
            <div style="box-shadow: inset 0 0 4px red;" class="flex">
                <div class="curve absolute top-[-43px] left-[27px]"
                    style="transform: rotateZ(-90deg) scale(0.14, 0.14);" :class="{
                        'curve-yellow': run.status == 'running',
                        'curve-green': run.status == 'finished',
                        'curve-red': run.status == 'failed',
                        'curve-gray': run.status == 'stopped',
                        'curve-blue': run.status == 'created' || run.status == 'started' || run.status == 'preparing',
                    }"></div>
                <div class="curve absolute top-[-43px] left-[-57px]" style="transform: scale(0.14, 0.14);" :class="{
                    'curve-yellow': run.status == 'running',
                    'curve-green': run.status == 'finished',
                    'curve-red': run.status == 'failed',
                    'curve-gray': run.status == 'stopped',
                    'curve-blue': run.status == 'created' || run.status == 'started' || run.status == 'preparing',
                }"></div>
            </div>
        </div>
        <div class="absolute dark:bg-gray-800 barberpole right-0 top-0 left-0 h-1 "
            :class="{ 'dark:border-yellow-400 dark:border-opacity-80 dashed-border': run.status == 'running' }">
            <div :class="get_run_status_border_class(run)"
                style="position: absolute; top: 0; left: 0; right: 0; height: 4px"
                :style="{ width: run.status == 'running' ? run.progress + '%' : '100%', transition: 'width 0.5s' }">
            </div>
        </div>
        <div class="flex flex-col lg:flex-row w-full h-full">
            <div class="lg:w-1/3">
                <div
                    class="py-4 flex flex-col border-r border-gray-700 h-full w-full dark:bg-gray-900 p-2 dark:bg-opacity-50 justify-stretch">
                    <div class="flex flex-col">
                        <div class="flex flex-row w-full justify-between pt-2 gap-3">
                            <div class="flex flex-col w-1/3">
                                <div class="dark:text-gray-600 text-sm font-medium text-center">API</div>
                                <div
                                    class="mt-0.5 border rounded-md px-1.5 text-center py-0.5 dark:text-gray-400 font-bold text-xs uppercase py-0 border-gray-800 text-center">
                                    {{ run.api }}
                                </div>
                            </div>

                            <div class="flex flex-col w-1/3">
                                <div class="dark:text-gray-600 text-sm font-medium text-center">Model</div>
                                <div
                                    class="mt-0.5 border rounded-md px-1.5 text-center py-0.5 dark:text-gray-400 font-bold text-xs uppercase py-0 border-gray-800 text-center">
                                    {{ run.model }}
                                </div>
                            </div>

                            <div class="flex flex-col w-1/3">
                                <div class="flex-col">
                                    <div class="dark:text-gray-600 font-medium text-sm font-medium text-center">
                                        Temperature</div>
                                    <div class="text-sm font-bold dark:text-gray-400 text-center">
                                        {{ parseFloat(run.temperature).toFixed(1) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lg:w-1/3">
                <div
                    class="pt-6 flex flex-col border-r border-gray-700 h-full w-full dark:bg-gray-900 dark:bg-opacity-50 justify-stretch">
                    <div class="flex flex-row items-center justify-between w-full">
                        <div class="flex flex-row w-full">
                            <div class="dark:text-gray-700 font-medium flex-col w-1/4">
                                <div class="dark:text-gray-600 font-medium text-center text-sm">Prompts</div>
                                <div class="text-sm font-bold text-center dark:text-gray-400 ">
                                    {{ format_number(run.current_prompt) }} / {{ format_number(run.total_prompts) }}</div>
                            </div>
                            <div class="flex flex-col  w-1/4">
                                <div class="dark:text-gray-600 font-medium text-center text-sm">Progress</div>
                                <span class="text-sm font-bold dark:text-gray-400 text-center"
                                    v-html="run.progress + ' %'"></span>
                            </div>
                            <div class="flex flex-col w-1/4">
                                <div class="dark:text-gray-600 font-medium text-center text-sm">Elapsed</div>
                                <span class="text-sm font-bold dark:text-gray-400 text-center" v-html="duration"></span>
                            </div>
                            <div class="mx-3 flex flex-col w-1/4">
                                <div class="dark:text-gray-600 font-medium text-center text-sm">ETA</div>
                                <div class="text-sm font-bold text-center"
                                    v-html="calculate_eta(run.total_prompts_tokens + run.total_responses_tokens, tokens_per_seconds)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lg:w-1/3">
                <div
                    class="pt-4 flex flex-col border-r border-gray-700 h-full w-full dark:bg-gray-900 px-6 dark:bg-opacity-50">
                    <div class="flex flex-row items-center">
                        <div class="text-lg text-gray-500 w-full text-opacity-50">
                            <div class="flex justify-between items-center w-full">
                                <div class="flex font-regular float-right mt-2 mr-1">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row items-center justify-between">
                        <div class="flex flex-col">
                            <div class="dark:text-gray-600 font-medium text-center text-sm">Prompts</div>
                            <div class="text-sm font-bold text-center">{{ format_number(run.total_prompts_tokens) }}
                            </div>
                        </div>
                        <div class="mx-3 dark:text-gray-700 font-bold ">+</div>
                        <div class="flex flex-col">
                            <div class="dark:text-gray-600 font-medium text-center text-sm">Responses</div>
                            <div class="text-sm font-bold text-center">{{ format_number(run.total_responses_tokens) }}
                            </div>
                        </div>
                        <div class="mx-3 dark:text-gray-700 font-bold ">=</div>
                        <div class="flex flex-col border-r border-dashed dark:border-gray-700 pr-6">
                            <div class="dark:text-gray-600 font-medium text-center text-sm">Total</div>
                            <div class="text-sm font-bold text-center">{{ format_number(run.total_prompts_tokens +
                                run.total_responses_tokens) }}</div>
                        </div>
                        <div class="ms-3 flex flex-col">
                            <div class="dark:text-gray-600 font-medium text-center text-sm">Tokens /s</div>
                            <div class="text-sm font-bold text-center">
                                {{ format_number(tokens_per_seconds.toFixed(0)) }}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="lg:w-1/3">
                <div
                    class="pt-6 flex flex-col h-full  border-r border-gray-700 w-full dark:bg-gray-900 p-2 dark:bg-opacity-50">
                    <div class="flex flex-row items-center justify-center">
                        <div class="flex flex-col w-1/3">
                            <div class="dark:text-gray-600 font-medium text-sm text-center">Prompts</div>
                            <div class="text-sm font-bold text-center" v-if="prices">{{
                                format_price(calculate_input_price(prices, run.model, run.total_prompts_tokens)) }}
                            </div>
                        </div>
                        <div class="mx-3 dark:text-gray-700 font-bold ">+</div>
                        <div class="flex flex-col w-1/3">
                            <div class="dark:text-gray-600 font-medium text-sm text-center">Responses</div>
                            <div class="text-sm font-bold text-center" v-if="prices">{{
                                format_price(calculate_output_price(prices, run.model, run.total_responses_tokens)) }}
                            </div>
                        </div>
                        <div class="mx-3 dark:text-gray-700 font-bold">=</div>
                        <div class="flex flex-col w-1/3">
                            <div class="dark:text-gray-600 font-medium text-sm text-center">Total</div>
                            <div class="text-sm font-bold text-center" v-if="prices">{{
                                format_price(calculate_input_price(prices, run.model,
                                    run.total_prompts_tokens) + calculate_output_price(prices, run.model,
                                        run.total_responses_tokens)) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ul
            class="flex h-full items-center relative flex-wrap text-sm font-medium text-center text-gray-500 border-b border-t border-gray-200 dark:border-gray-700 dark:text-gray-400">
            <li data-tooltip-target="overview_panel_tooltip">
                <div class="cursor-pointer inline-block p-2 lg:p-2 rounded-t-lg flex flex-col lg:flex-row items-center dark:hover:text-white hover:text-gray-900"
                    @click="toggle_overview_panel()">
                    <span class="dark:text-gray-500 dark:hover:text-gray-400">
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                d="M5 7h14M5 12h14M5 17h10" />
                        </svg>
                    </span>
                </div>
            </li>
            <div id="overview_panel_tooltip" role="tooltip"
                class="absolute z-50 invisible inline-block px-3 py-2 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 dark:text-gray-300 whitespace-nowrap select-none">
                {{ $store.state.show_overview_panel ? 'Hide' : 'Show' }} overview panel<div class="tooltip-arrow -ms-1"
                    data-popper-arrow></div>
            </div>


            <div class="flex flex-row items-center h-full">
                <div
                    class="flex flex-row flew-grow items-center h-full  border dark:border-gray-700 dark:border-opacity-50 rounded-full py-1 ps-1 pe-2.5">
                    <div class="flex-row text-center" data-tooltip-target="temperature_tooltip">
                        <div class="dark:text-gray-400 mx-1 cursor-pointer select-none font-bold text-sm"
                            @click="toggleTemperature()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                v-if="run.temperature == 0.0" class="bi bi-thermometer" viewBox="0 0 16 16">
                                <path d="M8 14a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" />
                                <path
                                    d="M8 0a2.5 2.5 0 0 0-2.5 2.5v7.55a3.5 3.5 0 1 0 5 0V2.5A2.5 2.5 0 0 0 8 0M6.5 2.5a1.5 1.5 0 1 1 3 0v7.987l.167.15a2.5 2.5 0 1 1-3.333 0l.166-.15z" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                v-else-if="run.temperature < 1.0" class="bi bi-thermometer-low" viewBox="0 0 16 16">
                                <path
                                    d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V9.5a.5.5 0 0 1 1 0v1.585a1.5 1.5 0 0 1 1 1.415" />
                                <path
                                    d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                v-else-if="run.temperature < 1.5" class="bi bi-thermometer-half" viewBox="0 0 16 16">
                                <path
                                    d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V6.5a.5.5 0 0 1 1 0v4.585a1.5 1.5 0 0 1 1 1.415" />
                                <path
                                    d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                v-else-if="run.temperature <= 2.0" class="bi bi-thermometer-high" viewBox="0 0 16 16">
                                <path
                                    d="M9.5 12.5a1.5 1.5 0 1 1-2-1.415V2.5a.5.5 0 0 1 1 0v8.585a1.5 1.5 0 0 1 1 1.415" />
                                <path
                                    d="M5.5 2.5a2.5 2.5 0 0 1 5 0v7.55a3.5 3.5 0 1 1-5 0zM8 1a1.5 1.5 0 0 0-1.5 1.5v7.987l-.167.15a2.5 2.5 0 1 0 3.333 0l-.166-.15V2.5A1.5 1.5 0 0 0 8 1" />
                            </svg>
                        </div>
                    </div>
                    <div class="relative ms-1 -mt-1.5" data-tooltip-target="temperature_tooltip">
                        <input id="labels-range-input" type="range" value="0.1" min="0" max="2" step="0.1"
                            v-model="run.temperature"
                            class="w-full h-1 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 dark:accent-red-500">
                    </div>
                </div>
                <div id="temperature_tooltip" role="tooltip"
                    class="absolute z-50 invisible inline-block px-3 py-2 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 dark:text-gray-300 whitespace-nowrap select-none">
                    Temperature {{ run.temperature }}<div class="tooltip-arrow -ms-1" data-popper-arrow></div>
                </div>
            </div>
            <div class="flex flex-row items-center h-full ms-1 me-1">
                <div data-tooltip-target="clear_logs_tooltip"
                    class="flex flex-row flew-grow items-center h-full cursor-pointer rounded-full py-1 ps-0.5 pe-0.5 ms-1 border dark:border-gray-700 dark:hover:border-blue-400 dark:hover:border-blue-400 dark:hover:text-blue-400 dark:hover:text-blue-400"
                    @click="clear_logs()">
                    <div class="flex-row text-center">
                        <div class="mx-1 select-none font-bold text-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512">
                                <path fill="currentColor"
                                    d="m153.654 18l52.57 134.734c1.698 3.994 4.05 5.83 7.243 6.977c3.2 1.15 7.36 1.2 11.058.17s6.71-3.146 7.996-4.915c1.288-1.77 1.634-2.564.505-5.24l-.046-.112L181.57 18zm94.168 120.143l1.88 4.81l-.09-.223c3.346 7.937 1.828 16.822-2.532 22.82c-4.36 5.996-10.773 9.734-17.723 11.67c-6.95 1.937-14.653 2.065-21.98-.57s-14.155-8.447-17.742-16.923l-.05-.118l-1.757-4.5c-31.31 19.804-42.47 42.026-35.367 68.89c1.24 4.681 3.422 12.364 5.964 22.13c74.37-5.274 139.945-23.872 199.808-51.6c-10.297-13.867-22.5-25.83-38.232-34.53c-20.505-11.34-47.652-20.157-72.178-21.857zm120.557 71.52c-61.497 28.81-129.173 48.378-205.575 54.196c2.03 8.683 4.08 18.28 5.95 28.495c89.592-10.084 163.043-26.22 217.755-48.767c-5.743-11.72-11.593-23.19-18.13-33.924m26.04 50.16c-57.093 23.772-131.99 40.087-222.73 50.322C180.697 371.423 179.614 446.752 128 480c16.27 0 31.892-.152 46.926-.45c17.84-25.554 31.27-66.222 32.08-86.146c8.27 16.793 3.297 59.32-5.36 85.434c2.735-.093 5.435-.193 8.127-.297c11.824-12.397 11.724-28.632 14.72-47.284c3.324 14.92 7 32.967 9.505 46.156c11.273-.616 22.152-1.34 32.606-2.183c16.38-20.358 21.65-49.604 18.63-85.48c4.226 29.1 9.116 62.138 11.873 82.55a772 772 0 0 0 27.807-3.614c5.04-18.787-4.1-48.444-2.072-69.54c11.123 43.113 22.247 55.45 33.37 64.043a456 456 0 0 0 15.733-3.526c-4.7-13.95 1.573-22.497 1.18-39.986c5.647 18.99 14.625 26.958 24.428 32.816c6.506-2.1 12.66-4.336 18.492-6.697c-10.538-6.57-10.113-26.374-12.38-42.926c5.954 21.703 14.413 32.418 24.083 37.816c29.124-13.8 48.69-31.534 60.398-53.657c-9.078-3.82-18.674-13.002-28.068-20.092c13.214 7.477 23.684 10.614 32.37 10.93a112 112 0 0 0 3.552-9.868c-56.326-19.528-80.07-64.018-101.58-108.178z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div id="clear_logs_tooltip" role="tooltip"
                    class="absolute z-50 invisible inline-block px-3 py-2 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 dark:text-gray-300 whitespace-nowrap select-none">
                    Clear logs<div class="tooltip-arrow -ms-1" data-popper-arrow></div>
                </div>
            </div>
            <div class="flex flex-row items-center h-full">
                <div data-tooltip-target="real_time_overview_tooltip"
                    class="flex flex-row flew-grow items-center h-full cursor-pointer rounded-full py-1 ps-0.5 pe-0.5 ms-1 "
                    :class="{
                        'border dark:border-blue-400 dark:hover:border-blue-400 dark:text-blue-400 dark:hover:text-blue-400': $store.state.real_time_overview,
                        'border dark:border-gray-700 dark:text-gray-600 dark:hover:border-blue-500 dark:hover:text-blue-500 ': !$store.state.real_time_overview,
                    }" @click="toggleRealTimeOverview()">
                    <div class="flex-row text-center">
                        <div class="mx-1 select-none font-bold text-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-clock-history" viewBox="0 0 16 16">
                                <path
                                    d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
                                <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                                <path
                                    d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div id="real_time_overview_tooltip" role="tooltip"
                    class="absolute z-50 invisible inline-block px-3 py-2 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 dark:text-gray-300 whitespace-nowrap select-none">
                    {{ $store.state.real_time_overview ? 'Disable' : 'Enable' }} real time overview<div
                        class="tooltip-arrow -ms-1" data-popper-arrow></div>
                </div>
            </div>
            <div class="flex flex-row items-center h-full ms-1">
                <div data-tooltip-target="toggle_expansion_tooltip"
                    class="flex flex-row flew-grow items-center h-full cursor-pointer rounded-full py-1 ps-0.5 pe-0.5 ms-1 "
                    :class="{
                        'border dark:border-blue-400 dark:hover:border-blue-400 dark:text-blue-400 dark:hover:text-blue-400': $store.state.expanded,
                        'border dark:border-gray-700 dark:text-gray-600 dark:hover:border-blue-500 dark:hover:text-blue-500 ': !$store.state.expanded,
                    }" @click="toggleExpansion()">
                    <div class="flex-row text-center">
                        <div class="mx-1 select-none font-bold text-sm">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                v-if="!$store.state.expanded" class="bi bi-arrows-angle-expand"
                                viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                v-if="$store.state.expanded" class="bi bi-arrows-angle-contract"
                                viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M.172 15.828a.5.5 0 0 0 .707 0l4.096-4.096V14.5a.5.5 0 1 0 1 0v-3.975a.5.5 0 0 0-.5-.5H1.5a.5.5 0 0 0 0 1h2.768L.172 15.121a.5.5 0 0 0 0 .707zM15.828.172a.5.5 0 0 0-.707 0l-4.096 4.096V1.5a.5.5 0 1 0-1 0v3.975a.5.5 0 0 0 .5.5H14.5a.5.5 0 0 0 0-1h-2.768L15.828.879a.5.5 0 0 0 0-.707z" />
                            </svg>
                        </div>
                    </div>
                </div>
                <div id="toggle_expansion_tooltip" role="tooltip"
                    class="absolute z-50 invisible inline-block px-3 py-2 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 dark:text-gray-300 whitespace-nowrap select-none">
                    {{ !$store.state.expanded ? 'Expand' : 'Reduce' }} all
                    <div class="tooltip-arrow -ms-1" data-popper-arrow></div>
                </div>
            </div>
            <div class="absolute right-1/2 -top-0.5 w-[15px]">
                <div class="flex flex-row">
                    <div class="flex-col items-center mt-1.5">
                        <div class="flex flex-row justify-center pr-2">
                            <button @click.stop.prevent="start_chat_run(run)"
                                v-if="run.status == 'created' || run.status == 'stopped' || run.status == 'failed' || run.status == 'finished'"
                                data-tooltip-target="tooltip-start-run"
                                class="px-1.5 rounded-full py-1.5 text-sm font-medium text-green-900 bg-white border border-green-200 hover:bg-green-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-green-950 dark:bg-opacity-50 dark:border-green-500 dark:hover:border-green-400 dark:text-green-400 dark:hover:text-green-300 dark:hover:bg-green-950 dark:hover:bg-opacity-50 dark:focus:ring-blue-500 dark:focus:text-white float-left flex flex-row items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    v-if="run.status != 'finished'" class="bi bi-play-fill" viewBox="0 0 16 16">
                                    <path
                                        d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                                </svg>
                                <svg class="w-[16px] h-[16px]" aria-hidden="true" v-if="run.status == 'finished'"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                    viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M17.651 7.65a7.131 7.131 0 0 0-12.68 3.15M18.001 4v4h-4m-7.652 8.35a7.13 7.13 0 0 0 12.68-3.15M6 20v-4h4" />
                                </svg>
                            </button>
                            <div id="tooltip-start-run" role="tooltip"
                                class="absolute z-10 invisible inline-block w-32 px-3 py-2 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                {{ run.status == 'finished' ? 'Res' : 'S' }}tart chat run
                                <div class="tooltip-arrow -ms-1" data-popper-arrow></div>
                            </div>
                            <button @click.stop.prevent="stop_chat_run(run)" v-if="is_run_active(run)"
                                data-tooltip-target="tooltip-stop-run"
                                class="px-1.5 py-1.5 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-full hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-950 dark:bg-opacity-50 dark:border-gray-500 dark:hover:border-gray-400 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-950 dark:hover:bg-opacity-50 dark:focus:ring-blue-500 dark:focus:text-white float-left flex flex-row items-center">
                                <svg class="w-[16px] h-[16px] text-gray-800 dark:text-white" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                    viewBox="0 0 24 24">
                                    <path fill-rule="evenodd"
                                        d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z"
                                        clip-rule="evenodd" />
                                </svg>

                            </button>
                            <div id="tooltip-stop-run" role="tooltip"
                                class="absolute z-10 invisible inline-block w-32 px-3 py-2 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                Pause chat run
                                <div class="tooltip-arrow -ms-1" data-popper-arrow></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="absolute right-1 top-0 bottom-0">
                <div class="flex flex-row items-center h-full">
                    <div class="flex flex-row ml-2 flew-grow items-center h-full">
                        <div class="flex-row text-center border py-1 rounded-full ps-2 pe-1 border transition" :class="{
                            'border dark:border-blue-600 dark:bg-blue-500 dark:bg-opacity-20 barberpole': run.dry_run,
                            'dark:border-gray-700': !run.dry_run
                        }">
                            <div class="relative flex items-center flex flex-row mr-2"
                                data-tooltip-target="tooltip-dry_run">
                                <label class="relative items-center cursor-pointer my-0.5">
                                    <input type="checkbox" value="" id="dry_run" class="sr-only peer"
                                        v-model="run.dry_run">
                                    <div
                                        class="w-7 h-4 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1.5px] after:start-[2px] after:end-[10px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                                    </div>
                                </label>
                                <label class="flex cursor-pointer ms-2 select-none font-bold text-xs uppercase"
                                    :class="{ 'dark:text-blue-400': run.dry_run, 'dark:text-gray-500': !run.dry_run }"
                                    for="dry_run">Dry run</label>
                            </div>
                            <div id="tooltip-dry_run" role="tooltip"
                                class="absolute z-10 invisible inline-block px-3 py-2 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700">
                                Dry run {{ run.dry_run ? 'enabled' : 'disabled' }}
                                <div class="tooltip-arrow" data-popper-arrow></div>
                            </div>
                        </div>
                    </div>
                    <button @click="handleModalDownloadVisibility(true)"
                        class="py-0.5 px-2.5 me-1 ms-1 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-blue-200 hover:bg-blue-100 hover:text-blue-700 focus:z-10 focus:ring-1 focus:ring-blue-200 dark:focus:ring-blue-700 dark:bg-blue-900 dark:text-blue-400 dark:border-blue-600 dark:hover:text-white dark:hover:bg-blue-700">
                        <div class="flex flex-row items-center">
                            <svg class="w-6 h-6 dark:text-opacity-70" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                viewBox="0 0 24 24">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                            </svg>
                            <div class="text-xs ml-2 font-bold uppercase">Download</div>
                        </div>
                    </button>
                    <button @click.stop.prevent="delete_chat_run(run)"
                        class="py-0.5 px-2.5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-red-200 hover:bg-red-100 hover:text-red-700 focus:z-10 focus:ring-1 focus:ring-red-200 dark:bg-red-950 dark:bg-opacity-50 dark:text-red-400 dark:border-red-600 dark:hover:text-white dark:hover:bg-red-700  dark:focus:ring-red-700">
                        <div class="flex flex-row items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                                class="w-6 h-6 dark:text-opacity-70 translate-y-1" viewBox="0 0 24 24">
                                <path
                                    d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                <path
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                            </svg>
                            <div class="text-xs font-bold uppercase">Delete</div>
                        </div>
                    </button>
                    <ul
                        class="flex h-full items-center relative flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                        <li data-tooltip-target="sources_panel_tooltip">
                            <div
                                class="cursor-pointer inline-block p-2 lg:p-2 rounded-t-lg flex flex-col lg:flex-row items-center dark:hover:text-white hover:text-gray-900">
                                <span class="dark:text-gray-500 dark:hover:text-gray-400"
                                    @click="toggle_sources_panel()">
                                    <svg class="w-5 h-5 rotate-180" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                        viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-width="2"
                                            d="M5 7h14M5 12h14M5 17h10" />
                                    </svg>
                                </span>
                            </div>
                        </li>
                        <div id="sources_panel_tooltip" role="tooltip"
                            class="absolute z-50 invisible inline-block px-3 py-2 text-xs font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700 dark:text-gray-300 whitespace-nowrap select-none">
                            {{ $store.state.show_sources_panel ? 'Hide' : 'Show' }} sources panel<div
                                class="tooltip-arrow -ms-1" data-popper-arrow></div>
                        </div>
                    </ul>
                </div>
            </div>
        </ul>
        <div class="w-full h-full dark:bg-gray-900" v-if="run && current_tab == 'Overview'">
            <Logs :run="run" :run-id="$route.params.runId" :chat-id="$route.params.chatId" />
        </div>
        <!-- <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 items-center w-full p-4"
            v-if="run && current_tab == 'Overview'">
            <Output :run="run" :chat-id="$route.params.chatId" />
        </div> -->
    </div>

    <ConfirmModal title="Delete chat run" content="Are you sure you want to delete this chat run ?" :item="confirmItem"
        :isVisible="showModalConfirm" v-if="confirmItem" @update:isVisible="handleConfirmModalVisibility"
        @update:confirmed="delete_run"></ConfirmModal>

    <DownloadChatRunModal title="Download Chat run" :run="run" :isVisible="showModal" v-if="run"
        @update:isVisible="handleModalDownloadVisibility" @update:run="updated_download_run">
    </DownloadChatRunModal>
</template>
<script>
import TimeMixin from '@/mixins/time.js';
import PricingMixin from '@/mixins/pricing.js';
import Gauge from '@/components/gauge.vue';
import Logs from '@/components/logs.vue';
import Output from '@/components/output.vue';
import ConfirmModal from '@/components/modals/confirm.vue';
import DownloadChatRunModal from '@/components/modals/download_chat_run.vue';
import { initTooltips } from 'flowbite';

export default {
    name: 'MonitorRun',
    mixins: [
        TimeMixin,
        PricingMixin
    ],
    components: {
        Gauge,
        Logs,
        Output,
        ConfirmModal,
        DownloadChatRunModal
    },
    data() {
        return {
            run: null,
            tabs: [
                //                 {
                //                     name: 'Overview', current: false, icon: `<svg class="w-5 h-5 text-gray-800 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                //   <path fill-rule="evenodd" d="M8.64 4.737A7.97 7.97 0 0 1 12 4a7.997 7.997 0 0 1 6.933 4.006h-.738c-.65 0-1.177.25-1.177.9 0 .33 0 2.04-2.026 2.008-1.972 0-1.972-1.732-1.972-2.008 0-1.429-.787-1.65-1.752-1.923-.374-.105-.774-.218-1.166-.411-1.004-.497-1.347-1.183-1.461-1.835ZM6 4a10.06 10.06 0 0 0-2.812 3.27A9.956 9.956 0 0 0 2 12c0 5.289 4.106 9.619 9.304 9.976l.054.004a10.12 10.12 0 0 0 1.155.007h.002a10.024 10.024 0 0 0 1.5-.19 9.925 9.925 0 0 0 2.259-.754 10.041 10.041 0 0 0 4.987-5.263A9.917 9.917 0 0 0 22 12a10.025 10.025 0 0 0-.315-2.5A10.001 10.001 0 0 0 12 2a9.964 9.964 0 0 0-6 2Zm13.372 11.113a2.575 2.575 0 0 0-.75-.112h-.217A3.405 3.405 0 0 0 15 18.405v1.014a8.027 8.027 0 0 0 4.372-4.307ZM12.114 20H12A8 8 0 0 1 5.1 7.95c.95.541 1.421 1.537 1.835 2.415.209.441.403.853.637 1.162.54.712 1.063 1.019 1.591 1.328.52.305 1.047.613 1.6 1.316 1.44 1.825 1.419 4.366 1.35 5.828Z" clip-rule="evenodd"/>
                // </svg>
                // `
                //                 },
                {
                    name: '', current: true, icon: `<svg class="w-5 h-5 text-gray-800 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h10"/>
                    </svg>` },
            ],
            current_chat: null,
            current_tab: 'Overview',
            confirmItem: null,
            showModalConfirm: false,
            durationUpdateInterval: null,
            prices: [],
            duration: 0,
            tokens_per_seconds: 0,
            last_tokens: 0,
            last_time: 0,
            showModal: false,
        }
    },
    sockets: {
        run_prepare(data) {
            if (!this.run) return;
            if (data.run_id == this.run._id) {
                this.run.status = data.status;
            }
        },
        run_start(data) {
            if (!this.run) return;
            if (data.run_id == this.run._id) {
                this.run.status = data.status;
                this.run.start_time = data.start_time;
                this.run.end_time = data.end_time;
                this.run.logs = data.logs;
                this.run.total_prompts = data.total_prompts;
                this.run.total_prompts_tokens = data.total_prompts_tokens;
                this.run.total_tokens = data.total_tokens;
                this.run.sectionsCompleted = data.sectionsCompleted;
            }
        },
        run_stopped(data) {
            if (!this.run) return;
            if (data.run_id == this.run._id) {
                this.run.status = data.status;
                this.run.end_time = data.end_time;
                this.run.logs = data.logs;
                this.run.sectionsCompleted = data.sectionsCompleted;
            }
        },
        run_end(data) {
            if (!this.run) return;
            if (data.run_id == this.run._id) {
                this.run.status = data.status;
                this.run.end_time = data.end_time;
                this.run.logs = data.logs;
                this.run.sectionsCompleted = data.sectionsCompleted;
            }
        },
        run_response(data) {
            if (!this.run) return;
            if (data.run_id == this.run._id) {
                this.run.responses.push(data)
            }
        },
        run_progress(data) {
            if (!this.run) return;
            if (data.run_id == this.run._id) {
                this.run.progress = data.progress;
                this.run.status = data.status;
                this.run.logs = data.logs;
                this.run.current_prompt = data.current_prompt;
                this.run.total_responses_tokens = data.total_responses_tokens;
                this.run.total_tokens = data.total_tokens;
                this.run.total_tasks = data.total_tasks;
                this.run.section_index = data.section_index;
                this.run.clip_index = data.clip_index;
                this.run.task_index = data.task_index;
                this.run.sectionsCompleted = data.sectionsCompleted;
                this.run.isPrePrompt = data.isPrePrompt;
            }
        },
        response_update(data) {
            if (!this.run) return;
            if (data.run_id == this.run._id) {
                this.run.total_responses_tokens = data.total_response_tokens;
            }
        },
    },
    mounted() {
        this.current_chat = {
            _id: this.$route.params.chatId
        };

        this.refresh(this.$route.params);

        this.startDurationUpdate();

        setInterval(() => {
            let currentTime = new Date().getTime() / 1000;
            let elapsedTime = currentTime - this.last_time;

            if (elapsedTime > 0) {
                this.tokens_per_seconds = (this.run.total_responses_tokens - this.last_tokens) / elapsedTime;
                this.last_time = currentTime;
                this.last_tokens = this.run.total_responses_tokens;
            }
            else {
                this.tokens_per_seconds = 0;
            }
        }, 1000);

        this.$forceUpdate()
        this.$nextTick(async () => {
            await initTooltips();
        });
    },
    beforeDestroy() {
        if (this.durationUpdateInterval) {
            clearInterval(this.durationUpdateInterval);
        }
    },
    methods: {
        toggleRealTimeOverview() {
            this.$store.dispatch('toggleRealTimeOverview', !this.$store.state.real_time_overview);
        },
        toggle_overview_panel() {
            this.$store.dispatch('toggleOverviewPanel', !this.$store.state.show_overview_panel);
        },
        toggleExpansion() {
            this.$store.dispatch('toggleExpansion', !this.$store.state.expanded);
        },
        clear_logs() {
            this.$store.dispatch('clearLogs', true);
        },
        toggle_sources_panel() {
            this.$store.dispatch('toggleSourcesPanel', !this.$store.state.show_sources_panel);
        },
        toggleTemperature() {
            if (this.run.temperature == 2.0) {
                this.run.temperature = 0.0;
            } else {
                this.run.temperature = 2.0;
            }
        },
        calculate_eta(tokens, tokens_per_seconds) {
            if (tokens_per_seconds == 0) return '∞';
            return this.secondsToDuration(((tokens - this.run.total_responses_tokens) / tokens_per_seconds).toFixed(0));
        },
        handleConfirmModalVisibility(value) {
            this.showModalConfirm = value;
        },
        refresh(params) {
            this.$store.dispatch('fetchChat', { chatId: params.chatId });
            this.$store.dispatch('fetchRun', {
                chatId: params.chatId,
                runId: params.runId
            });

            this.$store.dispatch('fetchVariables', { chatId: params.chatId });
            this.$store.dispatch('fetchWorkerPrompts', { chatId: params.chatId });
            this.$store.dispatch('fetchClipPrompt', { chatId: params.chatId, parse_vars: true });
            this.$store.dispatch('fetchSectionPrompt', { chatId: params.chatId, parse_vars: true });
            this.$store.dispatch('fetchSummaryPrompt', { chatId: params.chatId });
        },
        get_run_status_class(run) {
            if (run.status == 'created') return 'bg-blue-100 text-blue-800 text-xs font-bold px-1 py-0 rounded-t-none rounded-lg dark:bg-blue-600 dark:text-blue-200';
            if (run.status == 'started') return 'bg-blue-100 text-blue-800 text-xs font-bold px-1 py-0 rounded-t-none rounded-lg dark:bg-blue-600 dark:text-blue-200';
            if (run.status == 'preparing') return 'bg-blue-100 text-blue-800 text-xs font-bold px-1 py-0 rounded-t-none rounded-lg dark:bg-blue-900 dark:text-blue-300';
            if (run.status == 'running') return 'bg-yellow-100 text-yellow-800 text-xs font-bold px-1 py-0 rounded-t-none border-none rounded-lg dark:bg-yellow-400 dark:text-yellow-950';
            if (run.status == 'stopped') return 'bg-gray-100 text-gray-800 text-xs font-bold px-1 py-0 rounded-t-none rounded-lg dark:bg-gray-700 dark:text-gray-300';
            if (run.status == 'finished') return 'bg-green-100 text-green-800 text-xs font-bold px-1 py-0 rounded-t-none rounded-lg dark:bg-green-400 dark:text-green-950';
            if (run.status == 'errored') return 'bg-red-100 text-red-800 text-xs font-bold px-1 py-0 rounded-t-none rounded-lg dark:bg-red-900 dark:text-red-300';
            return '';
        },
        get_run_status_border_class(run) {
            if (run.status == 'created') return 'dark:bg-blue-600 dark:bg-opacity-100 barberpole';
            if (run.status == 'started') return 'dark:bg-blue-600 dark:bg-opacity- barberpole';
            if (run.status == 'preparing') return 'dark:bg-blue-300 dark:bg-opacity-100 barberpole animated';
            if (run.status == 'running') return 'dark:bg-yellow-400 dark:bg-opacity-100 barberpole animated';
            if (run.status == 'stopped') return 'dark:bg-gray-700 dark:bg-opacity-100 barberpole';
            if (run.status == 'finished') return 'dark:bg-green-400 dark:bg-opacity-100 barberpole';
            if (run.status == 'errored') return 'dark:bg-red-300 dark:bg-opacity-100 barberpole';
            return '';
        },
        format_number(num) {
            if (!num) return 0;
            return num.toLocaleString('fr-FR').replace(/,/g, ' ');
        },
        run_duration(run) {
            if (!run.start_time) return 0;
            if (!run.end_time) return (new Date().getTime() / 1000 - run.start_time).toFixed(0)
            return (run.end_time - run.start_time).toFixed(0)
        },
        delete_run(run) {
            this.$store.dispatch('deleteChatRun', {
                chatId: this.current_chat._id,
                runId: run._id
            });

            this.$router.push({
                name: 'monitor',
                params: {
                    chatId: this.current_chat._id
                }
            });
        },
        async delete_chat_run(run) {
            this.confirmItem = run;
            this.showModalConfirm = true;
        },
        debounce(func, wait, immediate) {
            let timeout;
            return function () {
                let context = this,
                    args = arguments;
                let later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                let callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },
        async start_chat_run(run) {
            this.debounce(async () => {
                this.$store.dispatch('startChatRun', {
                    chatId: this.current_chat._id,
                    runId: run._id,
                    is_restart: run.status == 'finished'
                });

                if (run.status == 'finished')
                    this.$store.dispatch('clearLogs', true);

                this.$forceUpdate()
                this.$nextTick(async () => {
                    await initTooltips();
                });
            }, 50)();
        },
        async stop_chat_run(run) {
            this.debounce(async () => {
                let res = await this.$store.dispatch('stopChatRun', {
                    chatId: this.current_chat._id,
                    runId: run._id
                });

                this.run.status = res.status;
                this.$forceUpdate()
            }, 50)();
        },
        is_run_active(run) {
            return run.status == 'running'
                || run.status == 'queued'
                || run.status == 'started'
                || run.status == 'preparing';
        },
        updateDuration() {
            if (this.run && this.is_run_active(this.run)) {
                this.duration = this.secondsToDuration(this.run_duration(this.run));
            }
        },
        startDurationUpdate() {
            if (this.durationUpdateInterval) {
                clearInterval(this.durationUpdateInterval);
            }
            this.durationUpdateInterval = setInterval(this.updateDuration, 1000);
        },
        handleModalDownloadVisibility(value) {
            this.showModal = value;
        },
        updated_download_run(run) {
            this.run = run;
        },
    },
    computed: {
        run_computed() {
            return this.$store.state.run;
        },
        prices_computed() {
            return this.$store.state.settings.prices;
        },
    },
    watch: {
        run_computed: {
            handler(newRun) {
                this.run = newRun;
            },
            deep: true,
            immediate: true
        },
        '$route.params': {
            handler(params) {
                this.refresh(params);
            },
            deep: true,
            immediate: true
        },
        'run.status': function (newStatus, oldStatus) {
            if (this.is_run_active(this.run)) {
                this.startDurationUpdate();
            } else if (this.durationUpdateInterval) {
                clearInterval(this.durationUpdateInterval);
            }

            this.$nextTick(async () => {
                await initTooltips();
            });
        },
        'run.dry_run': function (newDryRun, oldDryRun) {
            this.$store.dispatch('setRunDry', {
                chatId: this.current_chat._id,
                runId: this.run._id,
                dry_run: newDryRun
            });
        },
        prices_computed: {
            handler(newPrices) {
                this.prices = newPrices;
            },
            deep: true,
            immediate: true
        }
    },
}
</script>
<style scoped>
.curve {
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
}

.curve-yellow {
    background-repeat: no-repeat;
    background: radial-gradient(circle 150px at 0% 50%, transparent, transparent 100px, #E3A008 100px);
    background-size: 100px 200px, 100px 200px;
    background-position: 0 0, 100% 0;
}

.curve-green {
    background-repeat: no-repeat;
    background: radial-gradient(circle 150px at 0% 50%, transparent, transparent 100px, #31C48D 100px);
    background-size: 100px 200px, 100px 200px;
    background-position: 0 0, 100% 0;
}

.curve-blue {
    background-repeat: no-repeat;
    background: radial-gradient(circle 150px at 0% 50%, transparent, transparent 100px, #1C64F2 100px);
    background-size: 100px 200px, 100px 200px;
    background-position: 0 0, 100% 0;
}

.curve-red {
    background-repeat: no-repeat;
    background: radial-gradient(circle 150px at 0% 50%, transparent, transparent 100px, #EF4444 100px);
    background-size: 100px 200px, 100px 200px;
    background-position: 0 0, 100% 0;
}

.curve-gray {
    background-repeat: no-repeat;
    background: radial-gradient(circle 150px at 0% 50%, transparent, transparent 100px, #374151 100px);
    background-size: 100px 200px, 100px 200px;
    background-position: 0 0, 100% 0;
}

input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 20px;
    width: 12px;
    height: 12px;
    background: rgb(204, 204, 204);
    cursor: pointer;
}
</style>