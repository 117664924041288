<template>
    <ModalComponent :title="title" :isVisible="isVisible" @update:isVisible="handleVisibility">
        <template v-slot:title>
            {{ title }}
        </template>
        <template v-slot:body>
            <div class="p-4">
                <div class="p-4 text-sm text-red-800 rounded-lg bg-red-50 border border-red-500 border-opacity-30 dark:bg-red-950 dark:bg-opacity-50 flex flex-row items-center"
                    role="alert">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-exclamation-triangle-fill mr-3 dark:text-red-400" viewBox="0 0 16 16">
                        <path
                            d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
                    </svg>
                    <div class="dark:text-red-300">{{ content }}</div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div class="inline-flex rounded-md shadow-sm" role="group">
                <button type="button" @click="closeModal"
                    class="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white">
                    No
                </button>
                <button type="button" @click="validate"
                    class="px-4 py-2 text-sm font-medium text-red-900 bg-white border border-red-200 dark:border-opacity-40 rounded-lg rounded-l-none hover:bg-red-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-red-950 dark:bg-opacity-50 dark:border-red-500 dark:hover:border-red-400 dark:text-red-400 dark:hover:text-red-300 dark:hover:bg-red-950 dark:hover:bg-opacity-50 dark:focus:ring-blue-500 dark:focus:text-white float-left flex flex-row items-center">
                    Yes
                </button>
            </div>
        </template>
    </ModalComponent>
</template>
<script>
import ModalComponent from '@/components/modal.vue';
export default {
    components: {
        ModalComponent
    },
    name: 'ConfirmModal',
    props: {
        title: {
            type: String,
            default: 'Confirmation',
        },
        isVisible: {
            type: Boolean,
            required: true
        },
        content: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            showModal: false,
        };
    },
    methods: {
        validate() {
            this.$emit('update:confirmed', this.item);
            this.closeModal();
        },
        closeModal() {
            this.$emit('update:isVisible', false);
        },
        handleVisibility(val) {
            this.$emit('update:isVisible', val);
        }
    },
    watch: {
        watch: {
            showModal(val) {
                this.$emit('update:isVisible', val);
            }
        }
    },
}
</script>