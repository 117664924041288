<template>
    <ul
        class="md:hidden lg:hidden text-sm font-medium text-center text-gray-500 shadow flex dark:divide-gray-700 dark:text-gray-400">
        <li class="w-full" v-for="category in prompt_categories" :key="'desktop_menu_' + category.value">
            <a href="#" @click="show_menu_category(category)"
                class="inline-block w-full py-2 px-2 text-gray-900 bg-gray-100 focus:outline-none dark:bg-gray-700 dark:text-gray-400 flex flex-row items-center"
                :class="{
                    ' dark:bg-gray-800 bg-gray-800 dark:text-white border-b-2 border-blue-500 ': category.value == prompt_active_category,
                    ' dark:bg-gray-800 bg-gray-50 border-b-2 border-gray-900 ': category.value != prompt_active_category
                }" aria-current="page">
                <div v-html="category.icon" class="mr-3 mt-0.5"></div>
                <div class="text-xs">{{ category.name }}</div>
            </a>
        </li>
    </ul>
    <div class="flex">
        <ul class="hidden md:block lg:block flex-column p-0 space-y-0.5 text-sm font-medium text-gray-500 dark:text-gray-400"
            style="width: 215px;min-width: 215px;">
            <li v-for="category in prompt_categories" :key="'mobile_menu_' + category.value">
                <a href="#" @click="show_menu_category(category)"
                    class="inline-flex items-center px-4 py-2 text-white active w-full dark:text-gray-400" :class="{
                        ' dark:bg-gray-800 dark:text-white border-l-2 border-blue-500': category.value == prompt_active_category,
                        ' dark:bg-gray-800 bg-gray-50 border-l-2 border-transparent': category.value != prompt_active_category
                    }" aria-current="page">
                    <span v-html="category.icon" class="mr-3"></span>
                    {{ category.name }}
                </a>
            </li>
        </ul>

        <div
            class="bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 w-full shadow-md shadow-black z-10">
            <section class="bg-gray-50 dark:bg-gray-800 flex items-center pb-4"
                v-if="prompt_active_category == 'worker_prompts'">
                <div class="w-full">
                    <div class="relative bg-white dark:bg-gray-800">
                        <div
                            class="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                            <div class="w-full md:w-1/2">
                                <form class="flex items-center">
                                    <label for="simple-search" class="sr-only">Search...</label>
                                    <div class="relative w-full">
                                        <div class="relative">
                                            <div
                                                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                    fill="currentColor" viewbox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd"
                                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </div>
                                            <input type="text" id="simple-search"
                                                class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                                placeholder="Search..." required="" v-model="search_query">
                                            <div class="absolute right-3 top-3 bottom-0 cursor-pointer"
                                                @click="search_query = ''" v-show="search_query.length > 0">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                    fill="currentColor" class="bi bi-x-circle dark:fill-gray-400"
                                                    viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                    <path
                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="flex flex-row justify-end w-full md:w-auto md:flex-row md:items-center">
                                <button type="button" v-if="selected_worker_prompts.length > 0"
                                    class="text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-5 py-2 flex justify-center text-center text-center mb-1 flex-grow mr-2"
                                    @click="showSelectConfirmModal()">
                                    <div class="flex flex-row items-center ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                            style="transform: translateY(0px) scale(1.5) " class="pt-1 mr-1"
                                            viewBox="0 0 20 20">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path
                                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                        </svg>
                                        <div>Remove {{ selected_worker_prompts.length }} Prompt{{
                                            selected_worker_prompts.length > 1 ? 's' : '' }}</div>
                                    </div>
                                </button>

                                <button type="button"
                                    class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 flex justify-center text-center text-center mb-1 flex-grow"
                                    @click="create_worker_prompt()">
                                    <div class="flex flex-row items-center ">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                            style="transform: translateY(0px) scale(1.5) " class="pt-1 mr-1"
                                            viewBox="0 0 20 20">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path
                                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                        </svg>
                                        <div>Add Prompt</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="px-4">
                        <div class="w-full flex items-center p-2 text-sm text-blue-800 border border-blue-500 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:text-opacity-50 dark:border-blue-500 dark:border-opacity-20"
                            role="alert">
                            <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                            </svg>
                            <div>
                                <span class="font-medium text-xs lg:text-sm">These prompts are repeated for each clip in the
                                    summary.</span>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <form class="dark:bg-gray-800 p-3 pe-0 rounded-lg"
                :class="{ 'pt-0 ps-0': prompt_active_category == 'worker_prompts' }">
                <div v-show="prompt_active_category == 'default_prompt'"
                    :class="{ 'pe-3': !variables_sidebar_visible, 'h-full': true }">
                    <PromptEditor :prompt="default_prompt" @save:prompt="save_default_prompt"
                        info_text="This prompt is used as the system prompt." />
                </div>
                <div v-show="prompt_active_category == 'search_agent'"
                    :class="{ 'pe-3': !variables_sidebar_visible, 'h-full': true }">
                    <PromptEditor :prompt="search_agent_prompt" @save:prompt="save_search_agent_prompt"
                        info_text="This prompt is used to summarize the searched pages." />
                </div>
                <div v-show="prompt_active_category == 'summary_prompt'"
                    :class="{ 'pe-3': !variables_sidebar_visible, 'h-full': true }">
                    <!-- <PromptEditor :prompt="summary_prompt" @save:prompt="save_summary_prompt" :is_json="true"
                        info_text="The summary prompt as JSON format." /> -->
                    <!-- Section Editor -->
                    <div class="flex flex-grow mb-3 mt-3" v-if="show_save_button">
                        <button type="button"
                            class="transition-all bg-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 flex justify-center text-center text-center -mt-3.5 bg-gradient-to-r text-white cursor-pointer from-blue-500 via-blue-600 to-blue-700 focus:ring-blue-300 dark:focus:ring-blue-800 hover:bg-gradient-to-br"
                            @click="save_summary_prompt(summary_prompt_json)">
                            <div class="flex flex-row items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-floppy-fill mr-3" viewBox="0 0 16 16">
                                    <path
                                        d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z" />
                                    <path
                                        d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z" />
                                </svg>
                                <div>Save</div>
                            </div>
                        </button>
                    </div>
                    <div class="">
                        <div class="space-y-4">
                        <div v-for="(section, index) in summary_prompt_json" :key="index"
                            class="p-4 border border-gray-700 rounded">
                            <div class="flex items-center justify-between mb-2">
                                <input v-model="section.name" type="text" placeholder="Section Name"
                                    class="bg-gray-50 border border-gray-300 dark:border-opacity-70 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                                <button @click.prevent.stop="removeSection(index)"
                                    class="transition-all bg-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 flex justify-center text-center text-center from-gray-500 via-gray-600 to-gray-700 focus:ring-gray-300 dark:focus:ring-gray-800 text-gray-400 w-36 ml-3">Remove&nbsp;Section</button>
                            </div>
                            <div class="space-y-2 pl-8">
                                <!-- Sequence Editor -->
                                <div v-for="(sequence, seqIndex) in section.sequences" :key="seqIndex"
                                    class="flex items-center">
                                    <textarea v-model="sequence.name" type="text" spellcheck="false"
                                        placeholder="Sequence Name"
                                        class="bg-gray-50 border border-gray-300 dark:border-opacity-70 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                                        <textarea v-model="sequence.description" type="text" spellcheck="false"
                                        placeholder="Sequence Description"
                                        class="bg-gray-50 border border-gray-300 dark:border-opacity-70 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
                                    <button @click.prevent.stop="removeSequence(index, seqIndex)"
                                        class="transition-all bg-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 flex justify-center text-center text-center from-gray-500 via-gray-600 to-gray-700 focus:ring-gray-300 dark:focus:ring-gray-800 text-gray-400 w-36 ml-3">Remove&nbsp;Sequence</button>
                                </div>
                                <button @click.prevent.stop="addSequence(index)"
                                    class="transition-all bg-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 flex justify-center text-center text-center bg-gradient-to-r text-white cursor-pointer from-blue-500 via-blue-600 to-blue-700 focus:ring-blue-300 dark:focus:ring-blue-800 hover:bg-gradient-to-br w-36 ml-3">Add
                                    Sequence</button>
                            </div>
                        </div>
                        <button @click.prevent.stop="addSection"
                            class="transition-all bg-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 flex justify-center text-center text-center bg-gradient-to-r text-white cursor-pointer from-blue-500 via-blue-600 to-blue-700 focus:ring-blue-300 dark:focus:ring-blue-800 hover:bg-gradient-to-br w-36 ml-3">Add
                            Section</button>
                    </div>
                    </div>
                  

                </div>
                <div v-show="prompt_active_category == 'section_prompt'"
                    :class="{ 'pe-3': !variables_sidebar_visible, 'h-full': true }">
                    <PromptEditor :prompt="section_prompt" @save:prompt="save_section_prompt"
                        info_text="This prompt is repeated at each section." />
                </div>
                <div v-show="prompt_active_category == 'clip_prompt'"
                    :class="{ 'pe-3': !variables_sidebar_visible, 'h-full': true }">
                    <PromptEditor :prompt="clip_prompt" @save:prompt="save_clip_prompt"
                        info_text="This prompt is repeated at each sequence." />
                </div>
                <div v-show="prompt_active_category == 'worker_prompts'">
                    <div id="worker_prompts_table">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" class="px-2 py-2 text-center w-1">
                                        <div class="flex items-center">
                                            <input id="select-all-checkbox" type="checkbox"
                                                @click.stop="select_all_worker_prompts"
                                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                        </div>
                                    </th>
                                    <th scope="col" class="px-2 py-2 text-center w-1"></th>
                                    <th scope="col" class="px-2 py-2 text-center w-1"><span
                                            class="lg:flex hidden">Order</span><span class="lg:hidden">#</span></th>
                                    <th scope="col" class="px-2 py-2 text-left align-middle">Name</th>
                                    <th scope="col" class="px-2 py-2 text-center align-middle hidden lg:table-cell">
                                        Characters</th>
                                    <th scope="col" class="px-2 py-2 text-center align-middle hidden lg:table-cell">
                                        Tokens
                                    </th>
                                    <th scope="col" class="px-4 py-2 text-center align-middle">Actions</th>
                                </tr>
                            </thead>
                            <draggable v-model="worker_prompts" @start="dragging_wp_table = true"
                                @end="on_worker_prompts_table_drag_end" item-key="_id" tag="tbody" :animation="200"
                                :component-data="{ name: 'fade' }">
                                <template #item="{ element, index }">
                                    <tr @click.prevent.stop="edited_wp = element; showPromptModal = true"
                                        class="cursor-pointer bg-white border-b dark:bg-gray-800 dark:border-gray-700 dark:border-opacity-50 text-gray-900 dark:text-gray-400 hover:dark:text-gray-100">
                                        <td>
                                            <div class="flex items-center">
                                                <input :id="'select-checkbox_' + element._id" type="checkbox"
                                                    @click.stop="select_worker_prompt(element._id)"
                                                    :checked="is_worker_prompt_selected(element._id)"
                                                    class="ms-2 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                            </div>
                                        </td>
                                        <td class="cursor-move ps-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-grip-vertical fill-gray-600"
                                                viewBox="0 0 16 16">
                                                <path
                                                    d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                                            </svg>
                                        </td>
                                        <td class="px-1 py-1 text-center align-middle">
                                            {{ index + 1 }}
                                        </td>
                                        <th scope="row" class="px-2 py-2 font-medium whitespace-nowrap">
                                            <div class="w-72 lg:w-full font-semibold truncate">{{ element.name }}</div>
                                        </th>
                                        <td class="px-2 py-2 text-center hidden lg:table-cell">
                                            {{ element.prompt.length }}
                                        </td>
                                        <td class="px-2 py-2 text-center hidden lg:table-cell">
                                            {{ element.tokens }}
                                        </td>
                                        <td class="px-2 py-0">
                                            <div :data-dropdown-toggle="'dropdown_wp_toggle_' + element._id"
                                                :id="'dropdown_wp_menu_' + element._id" data-dropdown-offset-distance="0"
                                                data-dropdown-offset-skidding="0" data-dropdown-placement="right"
                                                @click.stop="" class="w-full h-6 mt-1.5">
                                                <svg class="w-4 h-4 text-gray-500 dark:text-gray-400 mx-auto"
                                                    aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                    fill="currentColor" viewBox="0 0 4 15">
                                                    <path
                                                        d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                                                </svg>
                                            </div>
                                            <div :id="'dropdown_wp_toggle_' + element._id"
                                                class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 shadow-lg">
                                                <ul class="text-sm text-gray-700 dark:text-gray-200"
                                                    :aria-labelledby="'dropdown_wp_toggle_' + element._id">
                                                    <li>
                                                        <a href="#" @click.prevent.stop="showEditPromptModal(element)"
                                                            class="block px-4 py-1 hover:rounded-t-lg hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit</a>
                                                    </li>
                                                    <li>
                                                        <a href="#"
                                                            class="block px-4 py-1 hover:rounded-b-lg hover:bg-gray-100 border-t border-gray-200 dark:border-gray-800 dark:bg-red-950 dark:bg-opacity-50 dark:text-red-400 rounded-b-lg dark:hover:text-red-300"
                                                            @click.prevent.stop="showConfirmModal(element)">Delete</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </draggable>
                        </table>
                    </div>
                </div>
            </form>
        </div>
        <aside id="variables-sidebar" v-show="variables_sidebar_visible"
            class="z-10 absolute top-[194px] sm:top-0 bottom-0 shadow-lg right-0 md:relative sm:relative lg:relative"
            aria-label="Sidebar">
            <div class="h-full px-3 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                <h4 class="text-lg mt-2.5 font-semibold dark:text-gray-400 mb-2.5">Variables</h4>
                <div
                    class="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-700 dark:text-gray-500">
                    <button aria-current="true" type="button" v-for="(variable, index) in variables_computed"
                        class="w-full px-4 py-2 font-medium text-left text-white dark:text-gray-400 dark:hover:text-gray-300 bg-blue-700 border-gray-200 cursor-pointer focus:outline-none dark:bg-gray-800 dark:border-gray-600 whitespace-nowrap overflow-hidden text-ellipsis"
                        :class="{ 'rounded-t-lg': index == 0, 'rounded-b-lg': index == variables_computed.length - 1, 'border-b': index != variables_computed.length - 1 }"
                        @click="inject_variable(prompt_active_category, variable)">
                        {{ variable.key }}
                    </button>
                </div>
            </div>
        </aside>
    </div>
    <EditPromptModal title="Edit prompt" :prompt="edited_wp" :isVisible="showPromptModal" v-if="edited_wp"
        @update:isVisible="handlePromptModalVisibility" @update:prompt="updated_prompt" @update:remove="removed_prompt">
    </EditPromptModal>
    <EditPromptModal title="Create prompt" :prompt="created_wp" :creating="true" :isVisible="showPromptModalCreate"
        v-if="created_wp" @update:isVisible="handlePromptCreateModalVisibility" @update:prompt="created_prompt">
    </EditPromptModal>
    <ConfirmModal title="Delete prompt" content="Are you sure you want to delete this prompt ?" :item="confirmItem"
        v-if="confirmItem" :isVisible="showPromptModalConfirm" @update:isVisible="handlePromptConfirmModalVisibility"
        @update:confirmed="delete_prompt"></ConfirmModal>
    <ConfirmModal title="Delete prompts" content="Are you sure you want to delete these(s) prompt(s) ?"
        :isVisible="showSelectPromptModalConfirm" @update:isVisible="handleSelectPromptConfirmModalVisibility"
        @update:confirmed="remove_selected_worker_prompts"></ConfirmModal>
</template>
<script>
import { initPopovers, initDrawers, Dropdown } from 'flowbite';
import VariablesMixin from '@/mixins/variables.js'
import EditPromptModal from '@/components/modals/edit_prompt.vue';
import ConfirmModal from '@/components/modals/confirm.vue';
import draggable from 'vuedraggable'
import PromptEditor from '@/components/prompt_editor.vue';

export default {
    name: 'Prompts',
    mixins: [VariablesMixin],
    components: {
        EditPromptModal,
        ConfirmModal,
        draggable,
        PromptEditor
    },
    data() {
        return {
            dragging_wp_table: false,
            prompt_active_category: 'search_agent',
            prompt_categories: [
                {
                    name: 'Search Agent',
                    value: 'search_agent',
                    description: 'This is the prompt used to summarize the searched pages.',
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg>`
                },
                {
                    name: 'Default',
                    value: 'default_prompt',
                    description: 'This is the initial prompt to which will be use as the "system" prompt.',
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-terminal" viewBox="0 0 16 16">
  <path d="M6 9a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 6 9M3.854 4.146a.5.5 0 1 0-.708.708L4.793 6.5 3.146 8.146a.5.5 0 1 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2z"/>
  <path d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1z"/>
</svg>`
                }, {
                    name: 'Summary',
                    value: 'summary_prompt',
                    description: 'This prompt is a summary in json format. Each section of the summary will execute the repeated prompts',
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-columns-reverse" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h2a.5.5 0 0 1 0 1h-2A.5.5 0 0 1 0 .5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10A.5.5 0 0 1 4 .5m-4 2A.5.5 0 0 1 .5 2h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 4h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 6h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2A.5.5 0 0 1 .5 8h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1h-10a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m-4 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m4 0a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5"/>
</svg>`
                },
                {
                    name: 'Section',
                    value: 'section_prompt',
                    description: 'This prompt is prepended before each section during the run.',
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ol" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5"/>
  <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635z"/>
</svg>`
                },
                {
                    name: 'Sequence',
                    value: 'clip_prompt',
                    description: 'This prompt is prepended before each sequence task during the run.',
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-ol" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5"/>
  <path d="M1.713 11.865v-.474H2c.217 0 .363-.137.363-.317 0-.185-.158-.31-.361-.31-.223 0-.367.152-.373.31h-.59c.016-.467.373-.787.986-.787.588-.002.954.291.957.703a.595.595 0 0 1-.492.594v.033a.615.615 0 0 1 .569.631c.003.533-.502.8-1.051.8-.656 0-1-.37-1.008-.794h.582c.008.178.186.306.422.309.254 0 .424-.145.422-.35-.002-.195-.155-.348-.414-.348h-.3zm-.004-4.699h-.604v-.035c0-.408.295-.844.958-.844.583 0 .96.326.96.756 0 .389-.257.617-.476.848l-.537.572v.03h1.054V9H1.143v-.395l.957-.99c.138-.142.293-.304.293-.508 0-.18-.147-.32-.342-.32a.33.33 0 0 0-.342.338zM2.564 5h-.635V2.924h-.031l-.598.42v-.567l.629-.443h.635z"/>
</svg>`
                },
                {
                    name: 'Tasks',
                    value: 'worker_prompts',
                    description: 'The repeated prompts are the prompts that will be executed in a loop. Each section of the summary will execute the repeated prompts',
                    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-repeat" viewBox="0 0 16 16">
  <path d="M11 5.466V4H5a4 4 0 0 0-3.584 5.777.5.5 0 1 1-.896.446A5 5 0 0 1 5 3h6V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192Zm3.81.086a.5.5 0 0 1 .67.225A5 5 0 0 1 11 13H5v1.466a.25.25 0 0 1-.41.192l-2.36-1.966a.25.25 0 0 1 0-.384l2.36-1.966a.25.25 0 0 1 .41.192V12h6a4 4 0 0 0 3.585-5.777.5.5 0 0 1 .225-.67Z"/>
</svg>`
                }],
            variables: [],
            default_prompt: '',
            default_prompt_tokens: 0,
            section_prompt: '',
            section_prompt_tokens: 0,
            clip_prompt: '',
            clip_prompt_tokens: 0,
            summary_prompt: '',
            summary_prompt_tokens: 0,
            search_agent_prompt: '',
            search_agent_prompt_tokens: 0,
            worker_prompts: [],
            creating_worker_prompt: false,
            creating_worker_prompt_name: '',
            creating_worker_prompt_prompt: '',
            current_chat: {},
            variables_sidebar_visible: false,
            show_default_prompt: true,
            created_wp: { 'name': '', 'prompt': '' },
            edited_wp: null,
            confirmItem: null,
            showPromptModal: false,
            showPromptModalCreate: false,
            showPromptModalConfirm: false,
            showSelectPromptModalConfirm: false,
            search_query: '',
            selected_worker_prompts: [],
            summary_prompt_json: [],
            show_save_button: true,
            isModified: false
        }
    },
    mounted() {
        this.current_chat = {
            _id: this.$route.params.chatId
        };
        this.$store.dispatch('fetchChat', { chatId: this.$route.params.chatId });
        this.$store.dispatch('fetchVariables', { chatId: this.$route.params.chatId });
        this.$store.dispatch('fetchWorkerPrompts', { chatId: this.$route.params.chatId });
        this.$store.dispatch('fetchDefaultPrompt', { chatId: this.$route.params.chatId });
        this.$store.dispatch('fetchSearchAgentPrompt', { chatId: this.$route.params.chatId });
        this.$store.dispatch('fetchSummaryPrompt', { chatId: this.$route.params.chatId });
        this.$store.dispatch('fetchSectionPrompt', { chatId: this.$route.params.chatId });
        this.$store.dispatch('fetchClipPrompt', { chatId: this.$route.params.chatId });

        this.worker.addEventListener('message', event => {
            const { action, textareaId, replacements, html } = event.data;

            for (let i = replacements.length - 1; i >= 0; i--) {
                const { startIndex, endIndex, replacementHTML } = replacements[i];
                document.getElementById(textareaId).innerHTML = document.getElementById(textareaId).innerHTML.slice(0, startIndex) + replacementHTML + document.getElementById(textareaId).innerHTML.slice(endIndex);
            }

            this.$nextTick(async () => {
                initPopovers();
                await this.$store.dispatch('setLoading', false)
            });
        });
    },
    methods: {
        addSection() {
            this.summary_prompt_json.push({
                name: '',
                sequences: []
            });
        },
        removeSection(index) {
            this.summary_prompt_json.splice(index, 1);
        },
        addSequence(sectionIndex) {
            this.summary_prompt_json[sectionIndex].sequences.push({ name: '', description: '' });
        },
        removeSequence(sectionIndex, sequenceIndex) {
            this.summary_prompt_json[sectionIndex].sequences.splice(sequenceIndex, 1);
        },
        select_worker_prompt(prompt_id) {
            if (this.selected_worker_prompts.indexOf(prompt_id) == -1) {
                this.selected_worker_prompts.push(prompt_id);
            } else {
                this.selected_worker_prompts = this.selected_worker_prompts.filter(wp => wp != prompt_id);
            }
        },
        select_all_worker_prompts() {
            for (let worker_prompt of this.worker_prompts) {
                this.select_worker_prompt(worker_prompt._id);
            }
        },
        remove_selected_worker_prompts() {
            this.$store.dispatch('deleteWorkerPrompts', {
                chatId: this.current_chat._id,
                prompt_ids: this.selected_worker_prompts,
                ctx: this
            });
        },
        is_worker_prompt_selected(prompt_id) {
            return this.selected_worker_prompts.indexOf(prompt_id) >= 0;
        },
        hideDropdown(variable_id) {
            this.$nextTick(() => {
                const target = document.querySelector('#dropdown_wp_toggle_' + variable_id);
                const trigger = document.querySelector('#dropdown_wp_menu_' + variable_id);
                if (!target || !trigger) return;
                const dropdown = new Dropdown(target, trigger, {
                    override: true,
                    triggerType: 'click',
                });
                dropdown.hide();
            });
        },
        showEditPromptModal(prompt) {
            this.edited_wp = prompt;
            this.hideDropdown(prompt._id);
            this.showPromptModal = true;
        },
        showConfirmModal(prompt) {
            this.confirmItem = prompt;
            this.hideDropdown(prompt._id);
            this.showPromptModalConfirm = true;
        },
        showSelectConfirmModal() {
            this.showSelectPromptModalConfirm = true;
        },
        handlePromptModalVisibility(newValue) {
            this.showPromptModal = newValue;
        },
        handlePromptCreateModalVisibility(newValue) {
            this.showPromptModalCreate = newValue;
        },
        handlePromptConfirmModalVisibility(newValue) {
            this.showPromptModalConfirm = newValue;
        },
        handleSelectPromptConfirmModalVisibility(newValue) {
            this.showSelectPromptModalConfirm = newValue;
        },
        updated_prompt(prompt) {
            this.$store.dispatch('updateWorkerPrompt', {
                chatId: this.current_chat._id,
                promptId: prompt._id,
                name: prompt.name,
                prompt: prompt.prompt
            });
        },
        removed_prompt(prompt) {
            this.confirmItem = prompt;
            this.showPromptModalConfirm = true;
        },
        created_prompt(prompt) {
            this.$store.dispatch('addWorkerPrompt', {
                chatId: this.current_chat._id,
                name: prompt.name,
                prompt: prompt.prompt
            });
        },
        save_default_prompt(prompt) {
            this.$store.dispatch('updateDefaultPrompt', {
                chatId: this.current_chat._id,
                default_prompt: prompt
            });
        },
        save_section_prompt(prompt) {
            this.$store.dispatch('setSectionPrompt', {
                chatId: this.current_chat._id,
                section_prompt: prompt
            });
        },
        save_clip_prompt(prompt) {
            this.$store.dispatch('setClipPrompt', {
                chatId: this.current_chat._id,
                clip_prompt: prompt
            });
        },
        save_summary_prompt(prompt) {
            this.$store.dispatch('setSummaryPrompt', {
                chatId: this.current_chat._id,
                summary_prompt: JSON.stringify({sections: prompt})
            });
        },
        save_search_agent_prompt(prompt) {
            this.$store.dispatch('setSearchAgentPrompt', {
                chatId: this.current_chat._id,
                search_agent_prompt: prompt
            });
        },
        delete_prompt() {
            this.$store.dispatch('deleteWorkerPrompt', {
                chatId: this.current_chat._id,
                prompt_id: this.confirmItem._id
            });
        },
        on_worker_prompts_table_drag_end() {
            this.$store.dispatch('updateWorkerPromptsOrder', {
                chatId: this.current_chat._id,
                promptsIds: this.worker_prompts.map(wp => wp._id)
            });
            this.dragging_wp_table = false;
        },
        calculate_worker_prompt_tokens(worker_prompt) {
            return worker_prompt.prompt.split(' ').length;
        },
        handleInput(event) {
            this.highlight_default_prompt();
            this.$store.dispatch('countTokens', { text: document.getElementById('default_prompt').innerText });
        },
        show_menu_category(category) {
            this.prompt_active_category = category.value;
            if (category.value == 'worker_prompts') {
                this.show_worker_prompts_panel();
            }
        },
        show_worker_prompts_panel() {
            this.$nextTick(() => {
                for (let worker_prompt of this.$store.state.worker_prompts)
                    this.highlightVariables('worker_prompts_' + worker_prompt._id, this.variables_computed);

                this.$nextTick(() => {
                    initPopovers();
                });
            });
        },
        async preview_default_prompt() {
            this.show_default_prompt = false;
            await this.$store.dispatch('setLoading', true)
            this.$nextTick(() => {
                this.previewVariables('default_prompt_preview', this.variables_computed_for_replacement);
                this.$nextTick(async () => {
                    initPopovers();
                    await this.$store.dispatch('setLoading', false)
                });
            });
        },
        async highlight_default_prompt() {
            this.show_default_prompt = true;
            await this.$store.dispatch('setLoading', true);
            this.$nextTick(() => {
                this.highlightVariables('default_prompt', this.variables_computed);
                this.$nextTick(async () => {
                    initPopovers();
                    await this.$store.dispatch('setLoading', false);
                });
            });
        },
        getCaretPosition() {
            if (window.getSelection && window.getSelection().getRangeAt) {
                var range = window.getSelection().getRangeAt(0);
                var selectedObj = window.getSelection();
                var rangeCount = 0;
                var childNodes = selectedObj.anchorNode.parentNode.childNodes;
                for (var i = 0; i < childNodes.length; i++) {
                    if (childNodes[i] == selectedObj.anchorNode) {
                        break;
                    }
                    if (childNodes[i].outerHTML)
                        rangeCount += childNodes[i].outerHTML.length;
                    else if (childNodes[i].nodeType == 3) {
                        rangeCount += childNodes[i].textContent.length;
                    }
                }
                return range.startOffset + rangeCount;
            }
            return -1;
        },
        setCursorPosition(el, pos) {
            try {
                var element = el;
                var selected = window.getSelection();
                var range = document.getRangeAt(0);
                range.setStart(element.childNodes[0], pos);
                range.collapse(true);
                selected.removeAllRanges();
                selected.addRange(range);
                element.focus();
                output.innerHTML = "";
            } catch (error) {
                console.log(error)
            }
        },
        createRange(node, targetPosition) {
            let range = document.createRange();
            range.selectNode(node);
            range.setStart(node, 0);

            let pos = 0;
            const stack = [node];
            while (stack.length > 0) {
                const current = stack.pop();

                if (current.nodeType === Node.TEXT_NODE) {
                    const len = current.textContent.length;

                    if (pos + len >= targetPosition) {
                        range.setEnd(current, targetPosition - pos);
                        return range;
                    }
                    pos += len;
                } else if (current.childNodes && current.childNodes.length > 0) {
                    for (let i = current.childNodes.length - 1; i >= 0; i--) {
                        stack.push(current.childNodes[i]);
                    }
                }
            }

            range.setEnd(node, node.childNodes.length);
            return range;
        },
        async delete_worker_prompt(worker_prompt) {
            await this.$store.dispatch('deleteWorkerPrompt', {
                chatId: this.current_chat._id,
                prompt_id: worker_prompt._id
            });
        },
        create_worker_prompt() {
            this.showPromptModalCreate = true;
        },
    },
    computed: {
        default_prompt_computed() {
            return this.$store.state.default_prompt;
        },
        summary_prompt_computed() {
            return this.$store.state.summary_prompt;
        },
        search_agent_prompt_computed() {
            return this.$store.state.search_agent_prompt;
        },
        section_prompt_computed() {
            return this.$store.state.section_prompt;
        },
        clip_prompt_computed() {
            return this.$store.state.clip_prompt;
        },
        worker_prompts_computed() {
            return this.$store.state.worker_prompts;
        },
        variables_computed() {
            return this.$store.state.variables;
        },
        variables_computed_for_replacement() {
            return this.$store.state.variables;
        },
        current_chat_computed() {
            return this.$store.state.current_chat;
        },
        default_prompt_tokens_computed() {
            return this.$store.state.default_prompt_tokens;
        }
    },
    watch: {
        default_prompt_tokens_computed(value) {
            this.default_prompt_tokens = value;
        },
        variables_computed(value) {
            this.variables = value;
            this.highlight_default_prompt();
        },
        default_prompt_computed(value) {
            this.default_prompt = value.replace(/\n/g, "<br>");
            this.$store.dispatch('countTokens', { text: this.default_prompt });
        },
        summary_prompt_computed(value) {
            try {
                this.summary_prompt = value.replace(/\n/g, "<br>");
                this.summary_prompt_json = JSON.parse(value).sections;

                this.$nextTick(() => {
                    initAccordions();
                });
            }
            catch (error) {
                console.log(error)
            }
        },
        search_agent_prompt_computed(value) {
            try {
                this.search_agent_prompt = value.replace(/\n/g, "<br>");
                this.$nextTick(() => {
                    initAccordions();
                });
            }
            catch (error) {
                console.log(error)
            }
        },
        section_prompt_computed(value) {
            this.section_prompt = value.replace(/\n/g, "<br>");
            this.$nextTick(() => {
                this.highlightVariables('section_prompt', this.variables_computed);
                this.$nextTick(() => {
                    initPopovers();
                });
            });
        },
        clip_prompt_computed(value) {
            this.clip_prompt = value.replace(/\n/g, "<br>");
            this.$nextTick(() => {
                this.highlightVariables('clip_prompt', this.variables_computed);
                this.$nextTick(() => {
                    initPopovers();
                });
            });
        },
        worker_prompts_computed(value) {
            this.worker_prompts = value;
        },
        '$route.params.chatId': {
            immediate: true,
            handler(newVal) {
                this.current_chat = {
                    _id: newVal
                };

                this.$store.dispatch('fetchVariables', { chatId: this.$route.params.chatId });
                this.$store.dispatch('fetchWorkerPrompts', { chatId: this.$route.params.chatId });
                this.$store.dispatch('fetchDefaultPrompt', { chatId: this.$route.params.chatId });
                this.$store.dispatch('fetchSearchAgentPrompt', { chatId: this.$route.params.chatId });
                this.$store.dispatch('fetchSummaryPrompt', { chatId: this.$route.params.chatId });
                this.$store.dispatch('fetchSectionPrompt', { chatId: this.$route.params.chatId });
                this.$store.dispatch('fetchClipPrompt', { chatId: this.$route.params.chatId });
            }
        },
    }
}
</script>
