export default {
    name: 'PricingMixin',
    methods: {
        calculate_input_price(prices, modelName, inputTokenCount) {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].model === modelName) {
                    return (inputTokenCount / 1000) * prices[i].input;
                }
            }
        },
        calculate_output_price(prices, modelName, outputTokenCount) {
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].model === modelName) {
                    return (outputTokenCount / 1000) * prices[i].output;
                }
            }
        },
        format_price(inputPrice) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(inputPrice);
        }
    }
}