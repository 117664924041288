<template>
    <div id="accordion-nested-parent" data-accordion="collapse" v-if="summary_prompt_final">
        <div v-for="(section, section_index) in summary_prompt_final.sections" :key="section_index">
            <h2 :id="'accordion-collapse-heading-' + section_index" class=" dark:bg-gray-800 transition rounded-none"
                :class="{
                    'rounded-b-none': isOpened(section_index),
                    'rounded-b-lg overflow-hidden': section_index == summary_prompt_final.sections.length - 1,
                    'rounded-t-lg overflow-hidden': section_index === 0
                }">
                <button type="button" v-if="run.sectionsCompleted"
                    class="flex items-center relative text-left w-full p-2 font-medium rtl:text-right text-gray-500 border-none border-gray-200 dark:border-gray-700 rounded-lg dark:text-gray-400 border-none dark:bg-gray-800 dark:hover:bg-gray-800 gap-3 transition"
                    @click="updateBorders(section_index)"
                    :data-accordion-target="'#accordion-collapse-body-' + section_index" aria-expanded="false"
                    :aria-controls="'accordion-collapse-body-' + section_index">
                    <svg v-if="isSectionCompleted(section_index)" xmlns="http://www.w3.org/2000/svg" width="16"
                        height="16" fill="currentColor" class="bi bi-check text-green-400 ml-1 transition  scale-125"
                        viewBox="0 0 16 16">
                        <path
                            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-clock-history text-gray-500 ml-1 transition" viewBox="0 0 16 16">
                        <path
                            d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483m.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535m-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                        <path
                            d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                    </svg>
                    <div class="absolute left-0 right-0 bottom-0 " v-if="!isSectionCompleted(section_index)">
                        <div class=" relative overflow-hidden h-[3px] text-xs flex bg-gray-700 w-full">
                            <div :style="{ width: getSectionProgress(section_index) + '%' }"
                                class="shadow-none transition flex flex-col text-center whitespace-nowrap text-white justify-center barberpole animated bg-yellow-400 ">
                            </div>
                        </div>
                    </div>
                    <div class="text-xs w-full truncate dark:text-gray-500" :class="{
                        'dark:text-green-400': isSectionCompleted(section_index),
                        'dark:text-gray-500': !isSectionCompleted(section_index)
                    }">{{ section.name }}</div>
                    <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0 mr-2 transition dark:text-gray-500"
                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9 5 5 1 1 5" />
                    </svg>
                </button>
            </h2>
            <div :id="'accordion-collapse-body-' + section_index" class="hidden "
                :aria-labelledby="'accordion-collapse-heading-' + section_index">
                <div class="p-3 ps-2 pe-2 pt-2 pb-2 border-none border-gray-200 dark:border-gray-700 dark:bg-gray-800"
                    :class="{ 'rounded-b-xl': section_index === summary_prompt_final.sections.length - 1 }">

                    <!-- <div class="mb-4 text-xs truncate">{{ section_prompt }}</div> -->

                    <div v-if="run.isPrePrompt"
                        class="mb-2 w-full text-xs truncate transition bg-clip-content dark:text-yellow-400">
                        <h3 class="flex flex-row items-center px-2 pt-1 gap-3">
                            <div class="spinner"></div>
                            <div>Pre-Prompts</div> 
                        </h3>
                    </div>

                    <div :id="'accordion-nested-collapse-' + section_index" data-accordion="collapse">
                        <div v-for="(clip, clip_index) in section.sequences" :key="'clip-' + clip_index">
                            <h2 :id="'accordion-nested-collapse-heading-' + section_index + '-' + clip_index">
                                <button type="button"
                                    class="flex text-sm text-left items-center w-full p-2 ps-2.5 font-medium rtl:text-right text-gray-500 border-none border-gray- dark:border-gray-700 dark:text-gray-500 hover:bg-gray-100 rounded-lg dark:hover:bg-gray-700 gap-3 relative transition"
                                    :data-accordion-target="'#accordion-nested-collapse-body-' + section_index + '-' + clip_index"
                                    aria-expanded="false"
                                    :aria-controls="'accordion-nested-collapse-body-' + section_index + '-' + clip_index">
                                    <svg v-if="isClipCompleted(section_index, clip_index)"
                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-check text-green-400 transition scale-125" viewBox="0 0 16 16">
                                        <path
                                            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                    </svg>
                                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" class="bi bi-clock-history text-gray-500"
                                        viewBox="0 0 16 16">
                                        <path
                                            d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483m.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535m-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                                        <path
                                            d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                                    </svg>

                                    <div class="w-full truncate text-xs dark:text-gray-500 transition bg-clip-content"
                                        :class="{
                                            'dark:text-green-400': isClipCompleted(section_index, clip_index),
                                        }">
                                        {{ clip.name }}
                                    </div>
                                    <svg data-accordion-icon
                                        class="w-3 h-3 rotate-180 shrink-0 transition dark:text-gray-500"
                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                                        viewBox="0 0 10 6">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="M9 5 5 1 1 5" />
                                    </svg>
                                </button>
                            </h2>
                            <div :id="'accordion-nested-collapse-body-' + section_index + '-' + clip_index"
                                class="hidden"
                                :aria-labelledby="'accordion-nested-collapse-heading-' + section_index + '-' + clip_index">

                                <div
                                    class="p-3 pb-0 pt-0 ml-4 border-l border-gray-200 dark:border-gray-700 dark:text-gray-500 relative">
                                    <!-- <div class="mb-4 text-xs  dark:text-gray-400 truncate">{{ clip_prompt }}</div> -->

                                    <div :id="'accordion-nested-worker-prompt-' + section_index + '-' + clip_index"
                                        data-accordion="collapse">

                                        <div v-for="(prompt, prompt_index) in worker_prompts"
                                            :key="'prompt-' + prompt._id">
                                            <h3
                                                :id="'accordion-nested-worker-prompt-heading-' + section_index + '-' + clip_index + '-' + prompt_index">
                                                <button type="button"
                                                    class="flex text-sm text-left w-full items-center w-full p-2 rounded-lg font-medium rtl:text-right text-gray-500 dark:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 gap-3"
                                                    aria-expanded="false"
                                                    :aria-controls="'accordion-nested-worker-prompt-body-' + section_index + '-' + clip_index + '-' + prompt_index">
                                                    <div v-if="isTaskInProgress(section_index, clip_index, prompt_index)"
                                                        class="spinner"></div>
                                                    <svg v-else-if="isTaskCompleted(section_index, clip_index, prompt_index)"
                                                        xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                        fill="currentColor"
                                                        class="bi bi-check text-green-400 transition scale-125"
                                                        viewBox="0 0 16 16">
                                                        <path
                                                            d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                                                    </svg>
                                                    <svg v-else xmlns="http://www.w3.org/2000/svg" width="16"
                                                        height="16" fill="currentColor"
                                                        class="bi bi-clock-history text-gray-500" viewBox="0 0 16 16">
                                                        <path
                                                            d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483m.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535m-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                                        <path
                                                            d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
                                                        <path
                                                            d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
                                                    </svg>
                                                    <h1 class="w-full text-xs truncate transition bg-clip-content"
                                                        :class="{
                                                            'dark:text-green-400': isTaskCompleted(section_index, clip_index, prompt_index) && !isTaskInProgress(section_index, clip_index, prompt_index),
                                                            'dark:text-yellow-400': isTaskInProgress(section_index, clip_index, prompt_index)
                                                        }">

                                                        {{ prompt.name }}
                                                    </h1>
                                                </button>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { initAccordions, initPopovers } from 'flowbite'
import VariablesMixin from '@/mixins/variables.js'

export default {
    mixins: [VariablesMixin],
    props: [
        'chatId',
        'run',
    ],
    data() {
        return {
            auto_expand: true,
            section_prompt: null,
            clip_prompt: null,
            summary_prompt_final: null,
            worker_prompts: null,
            openAccordionIndices: {
                section: null,
                clip: null,
                task: null
            },
            opened_sections: [],
        }
    },

    async mounted() {
        await this.$store.dispatch('fetchChat', { chatId: this.$route.params.chatId });
    },
    methods: {
        get_response(sectionIndex, clipIndex, promptIndex) {
            const response = this.run.responses.find((response) => {
                return response.section_index === sectionIndex &&
                    response.clip_index === clipIndex &&
                    response.task_index === promptIndex;
            });
            if (!response) return '';
            return response.response;
        },
        isSectionCompleted(sectionIndex) {
            // if (this.run.isPrePrompt)
            //     return false;
            return this.run.sectionsCompleted.includes(sectionIndex);
        },
        isClipCompleted(sectionIndex, clipIndex) {
            // if (this.run.isPrePrompt)
            //     return false;

            if (this.run.section_index > sectionIndex) {
                return true;
            }
            else if (this.run.section_index === sectionIndex && this.run.clip_index > clipIndex) {
                return true;
            }
            return false;
        },
        isTaskCompleted(sectionIndex, clipIndex, taskIndex) {
            if (this.run.isPrePrompt)
                return false;

            if (this.run.section_index > sectionIndex) {
                return true;
            }
            else if (this.run.section_index === sectionIndex) {
                if (this.run.clip_index > clipIndex) {
                    return true;
                }
                else if (this.run.clip_index === clipIndex) {
                    return this.run.task_index >= taskIndex;
                }
            }
            return false;
        },
        isTaskInProgress(sectionIndex, clipIndex, taskIndex) {
            return this.run.section_index === sectionIndex &&
                this.run.clip_index === clipIndex &&
                this.run.task_index === taskIndex;
        },
        getSectionProgress(sectionIndex) {
            if (sectionIndex < this.run.section_index) {
                return 100;
            } else if (sectionIndex > this.run.section_index) {
                return 0;
            } else {
                const totalClipsInSection = this.summary_prompt_final.sections[sectionIndex].sequences.length;
                return (this.run.clip_index / totalClipsInSection) * 100;
            }
        },
        getClipProgress(sectionIndex, clipIndex) {
            if (sectionIndex < this.run.section_index ||
                (sectionIndex === this.run.section_index && clipIndex < this.run.clip_index)) {
                return 100;
            } else if (sectionIndex > this.run.section_index ||
                (sectionIndex === this.run.section_index && clipIndex > this.run.clip_index)) {
                return 0;
            } else {
                return ((this.run.task_index) / this.run.total_tasks) * 100;
            }
        },
        openAllAccordions() {
            this.toggleAllAccordions(true);
        },
        closeAllAccordions() {
            this.toggleAllAccordions(false);
        },
        toggleAllAccordions(open) {
            const accordions = document.querySelectorAll('#accordion-nested-parent [data-accordion-target]');
            accordions.forEach((accordion) => {
                accordion.setAttribute('data-accordion', open ? 'expand' : 'collapse');
                const accordionTarget = accordion.getAttribute('data-accordion-target');
                const accordionBody = document.querySelector(accordionTarget);
                if (!accordionBody) return;
                if (open) {
                    accordionBody.classList.remove('hidden');
                    accordion.setAttribute('aria-expanded', 'true');
                } else {
                    accordionBody.classList.add('hidden');
                    accordion.setAttribute('aria-expanded', 'false');
                }
            });
        },
        updateAccordionState(sectionIndex, clipIndex, taskIndex) {
            this.updateBorders(sectionIndex);
            this.openAccordionIndices = { section: sectionIndex, clip: clipIndex, task: taskIndex };
            this.$nextTick(() => {
                this.openSpecificAccordion();
            });
        },
        openSpecificAccordion() {
            this.closeAllAccordions();

            const sectionAccordion = document.querySelector(`#accordion-collapse-body-${this.openAccordionIndices.section}`);
            const clipAccordion = document.querySelector(`#accordion-nested-collapse-body-${this.openAccordionIndices.section}-${this.openAccordionIndices.clip}`);
            const taskAccordion = document.querySelector(`#accordion-nested-worker-prompt-body-${this.openAccordionIndices.section}-${this.openAccordionIndices.clip}-${this.openAccordionIndices.task}`);

            if (sectionAccordion) {
                sectionAccordion.classList.remove('hidden');
                sectionAccordion.previousElementSibling.setAttribute('aria-expanded', 'true');
            }
            if (clipAccordion) {
                clipAccordion.classList.remove('hidden');
                clipAccordion.previousElementSibling.setAttribute('aria-expanded', 'true');
            }
            if (taskAccordion) {
                taskAccordion.classList.remove('hidden');
                taskAccordion.previousElementSibling.setAttribute('aria-expanded', 'true');
            }
        },
        isOpened(sectionIndex) {
            return this.opened_sections.includes(sectionIndex);
        },
        updateBorders(sectionIndex) {
            if (this.opened_sections.includes(sectionIndex)) {
                this.opened_sections = this.opened_sections.filter((index) => index !== sectionIndex);
            } else {
                this.opened_sections.push(sectionIndex);
            }
        }
    },
    computed: {
        section_prompt_computed() {
            return this.$store.state.section_prompt;
        },
        summary_prompt_computed() {
            return this.$store.state.summary_prompt;
        },
        clip_prompt_computed() {
            return this.$store.state.clip_prompt;
        },
        worker_prompts_computed() {
            let prompts = this.$store.state.worker_prompts;
            for (let prompt of prompts)
                prompt.prompt = prompt.prompt.replace(/\n/g, "<br>");
            return prompts;
        },
        variables_computed() {
            return this.$store.state.variables;
        },
    },
    watch: {
        section_prompt_computed: {
            immediate: true,
            deep: true,
            handler(value) {
                this.section_prompt = value;
            }
        },
        worker_prompts_computed: {
            immediate: true,
            deep: true,
            handler(value) {
                this.worker_prompts = value;
            }
        },
        clip_prompt_computed: {
            immediate: true,
            deep: true,
            handler(value) {
                this.clip_prompt = value;
            }
        },
        summary_prompt_computed: {
            immediate: true,
            deep: true,
            handler(value) {
                try {
                    const data = JSON.parse(value);
                    if (!data) return;

                    this.summary_prompt_final = data
                    this.$nextTick(() => {
                        initAccordions();
                    });
                }
                catch (error) {
                    this.summary_prompt_final = null;
                }
            }
        },
        'run.section_index': function (newVal) {
            if (this.auto_expand)
                this.updateAccordionState(newVal, this.run.clip_index, this.run.task_index);
        },
        'run.clip_index': function (newVal) {
            if (this.auto_expand)
                this.updateAccordionState(this.run.section_index, newVal, this.run.task_index);
        },
        'run.task_index': function (newVal) {
            if (this.auto_expand)
                this.updateAccordionState(this.run.section_index, this.run.clip_index, newVal);
        },
        '$store.state.expanded': function (value) {
            if (value) {
                this.openAllAccordions();
            } else {
                this.closeAllAccordions();
            }

        }
    }
}
</script>
<style scoped>
.spinner {
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    border-top-color: #E3A008;
    border-bottom-color: #E3A008;
    border-left-color: rgba(255, 255, 255, 0.1);
    border-right-color: rgba(255, 255, 255, 0.1);
}

.spinner:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: #E3A008;
    border-radius: 100%;
    margin-left: calc(50% - 4px);
    margin-top: calc(50% - 3px);
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }

}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>