<template>
    <div class="flex flex-row items-center text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        <div class="me-1 flex">
            <div aria-current="page" @click.prevent="highlight_prompt()"
                class="cursor-pointer inline-block px-3 py-2 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                :class="{ 'dark:bg-gray-700': !show_preview, 'dark:bg-transparent': show_preview }">
                Prompt</div>
        </div>
        <div class="me-1 flex">
            <div @click.prevent="preview_prompt()"
                class="cursor-pointer inline-block px-3 py-2 rounded-t-lg hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                :class="{ 'dark:bg-gray-700': show_preview, 'dark:bg-transparent': !show_preview }">
                Preview
            </div>
        </div>
        <div class="flex flex-grow"></div>
        <div class="flex items-center -mt-3.5 mr-3">
            <div v-if="isModified" class="mr-2 text-gray-500 flex flex-grow items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="flex bi bi-exclamation-circle mr-2" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path
                        d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                </svg>
                <span class="flex">Unsaved Changes</span>
            </div>
        </div>
        <div class="flex" v-if="show_save_button">
            <button type="button"
                class="transition-all bg-gray-700 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 flex justify-center text-center text-center -mt-3.5"
                :class="isModified ? 'bg-gradient-to-r text-white cursor-pointer from-blue-500 via-blue-600 to-blue-700 focus:ring-blue-300 dark:focus:ring-blue-800 hover:bg-gradient-to-br' : 'from-gray-500 via-gray-600 to-gray-700 focus:ring-gray-300 dark:focus:ring-gray-800 text-gray-400 cursor-not-allowed'"
                @click="save_prompt()">
                <div class="flex flex-row items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-floppy-fill mr-3" viewBox="0 0 16 16">
                        <path
                            d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z" />
                        <path
                            d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z" />
                    </svg>
                    <div>Save</div>
                </div>
            </button>
        </div>
    </div>
    <textarea :id="'prompt_editor_' + prompt_id" spellcheck="false" v-show="!show_preview" v-model="prompt_data"
        :class="'z-10 overflow-x-hidden overflow-y-scroll block p-2.5 w-full text-sm text-gray-900 rounded-tl-none bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 overflow-y-scroll ' + height_class">
    </textarea>
    <div class="relative preview-container" v-show="show_preview && !is_json">
        <div :id="'prompt_editor_preview_' + prompt_id"
            :class="'cursor-not-allowed overflow-x-hidden overflow-y-scroll block p-2.5 w-full text-sm text-gray-900 rounded-tl-none bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 overflow-y-scroll ' + height_class"
            v-html="prompt_preview">
        </div>
        <div class="absolute cursor-pointer rounded-bl-lg rounded-tr-lg px-2 py-0.5 top-0 right-0 preview_copy dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600 dark:border-opacity-20"
            :class="{ ' dark:bg-green-500 dark:text-white ': copied, ' dark:bg-gray-50 dark:text-gray-500 ': !copied }"
            @click="copyToClipboard('prompt_editor_preview_' + prompt_id)">
            <div class="flex flex-row items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard"
                    v-if="!copied"
                    :class="{ ' dark:text-white ': copied, ' dark:text-gray-400 ': !copied }" viewBox="0 0 16 16">
                    <path
                        d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                    <path
                        d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    v-if="copied"
                    :class="{ ' dark:text-white ': copied, ' dark:text-gray-400 ': !copied }"
                    class="bi bi-clipboard-check" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                        d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                    <path
                        d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z" />
                    <path
                        d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z" />
                </svg>
                <div class="ml-2 text-sm">{{ copy_text }}</div>
            </div>
        </div>
    </div>
    <div class="relative preview-container" v-show="show_preview && is_json">
        <pre v-highlightjs :class="'cursor-not-allowed overflow-x-hidden overflow-y-scroll block p-2.5 w-full text-sm text-gray-900 rounded-tl-none bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 overflow-y-scroll ' + height_class"><code class="javascript">{{prompt_preview.replace(/<br\s*\/?>/gi, '\n')}}</code></pre>
    </div>
    <div class="text-sm flex flex-row items-center mb-0">
        <div class="text-xs lg:text-sm dark:text-gray-500 mr-4 mt-2.5">
            <div>Characters</div>
            <b>{{ prompt_length }}</b>
        </div>
        <div class="text-xs lg:text-sm dark:text-gray-500 mr-4 lg:w-20 mt-2.5">
            <div>Tokens</div>
            <b>{{ prompt_tokens }}</b>
        </div>
        <div class="text-xs lg:text-sm dark:text-gray-800 mr-1 lg:w-full mt-2.5" v-if="info_text.length > 0">
            <div class="flex items-center p-2 text-sm text-blue-800 border border-blue-500 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400 dark:text-opacity-50 dark:border-blue-500 dark:border-opacity-20"
                role="alert">
                <svg class="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor" viewBox="0 0 20 20">
                    <path
                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <div>
                    <span class="text-xs lg:text-sm">{{ info_text }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { initPopovers } from 'flowbite';
import VariablesMixin from '@/mixins/variables.js'

export default {
    mixins: [VariablesMixin],
    name: 'PromptEditor',
    data() {
        return {
            prompt_id: null,
            prompt_preview: '',
            prompt_data: '',
            original_data: '',
            prompt_tokens: 0,
            show_preview: false,
            debounce_time: 200,
            first_load: true,
            isModified: false,
            copied: false,
            copy_text: 'Copy',
        }
    },
    mounted() {
        this.prompt_id = this.generate_random_id();
        this.worker.addEventListener('message', event => {
            const { action, textareaId, replacements, html } = event.data;

            for (let i = replacements.length - 1; i >= 0; i--) {
                const { startIndex, endIndex, replacementHTML } = replacements[i];
                document.getElementById(textareaId).innerHTML = document.getElementById(textareaId).innerHTML.slice(0, startIndex) + replacementHTML + document.getElementById(textareaId).innerHTML.slice(endIndex);
            }

            this.$nextTick(async () => {
                initPopovers();
                await this.$store.dispatch('setLoading', false)
            });
        });
    },
    emits: ['save:prompt', 'update:prompt'],
    props: {
        prompt: {
            type: String,
            default: ''
        },
        show_save_button: {
            type: Boolean,
            default: true
        },
        height_class: {
            type: String,
            default: 'area_modal_height'
        },
        tokens: {
            type: Number,
            default: 0
        },
        info_text: {
            type: String,
            default: ''
        },
        is_json: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        prompt: {
            async handler(newPrompt) {
                this.prompt_preview = newPrompt.replace(/\n/gi, '<br>');
                this.original_data = newPrompt.replace(/<br\s*\/?>/gi, '\n');
                this.prompt_data = this.original_data;
            },
            immediate: true,
            deep: true
        },
        prompt_data: {
            async handler(newPrompt) {
                let prompt = newPrompt.replace(/<br\s*\/?>/gi, '\n');

                this.prompt_preview = prompt.replace(/\n/gi, '<br>');
                this.$emit('update:prompt', prompt);

                if (this.first_load) {
                    this.first_load = false;
                    return;
                }

                this.isModified = prompt.length !== this.original_data.length;

                this.debounce(this.count_tokens, this.debounce_time)(prompt)
            },
            immediate: true
        },
        tokens: {
            async handler(data) {
                this.prompt_tokens = data;
            },
            immediate: true
        }
    },
    computed: {
        variables_computed() {
            return this.$store.state.variables;
        },
        prompt_length() {
            return this.prompt_data.length;
        }
    },
    methods: {
        copyToClipboard(elementId) {
            const el = document.getElementById(elementId);
            const range = document.createRange();
            range.selectNodeContents(el);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
            document.execCommand('copy');
            sel.removeAllRanges();
            this.copied = true;
            this.copy_text = 'Copied !';

            setTimeout(() => {
                this.copied = false;
                this.copy_text = 'Copy';
            }, 700);
        },
        debounce(func, wait) {
            let timeout;
            return (...args) => {
                const context = this;
                const later = () => {
                    clearTimeout(timeout);
                    func.apply(context, args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        },
        async count_tokens(text) {
            this.prompt_tokens = Math.max(parseInt(await this.$store.dispatch('countTokens', { text })), 0)
        },
        generate_random_id() {
            return Math.random().toString(16).slice(2)
        },
        highlight_prompt() {
            this.show_preview = false;
        },
        preview_prompt() {
            this.show_preview = true;
            this.$store.dispatch('setLoading', true)
            this.$nextTick(() => {
                this.previewVariables('prompt_editor_preview_' + this.prompt_id, this.variables_computed);
                this.$nextTick(() => {
                    initPopovers();
                    this.$store.dispatch('setLoading', false)
                });
            });
        },
        save_prompt() {
            if (this.isModified) {
                this.$emit('save:prompt', this.prompt_data);
                this.isModified = false;
            }
        },
    }
}

</script>
<style scoped>
.area_modal_height {
    height: calc(100vh - 212px) !important;
}

@media only screen and (max-width: 600px) {
    .area_modal_height {
        height: calc(100vh - 248px) !important;
    }
}

.preview_copy {
    display: none;
    transition: all .2s;
}

.preview-container:hover .preview_copy {
    display: block;
}</style>
