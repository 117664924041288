import { createStore } from 'vuex'

let api_base = 'http://localhost:5005';

if (process.env.NODE_ENV === 'production')
  api_base = process.env.VUE_APP_API_BASE;

console.log('API_BASE', process.env)

export default createStore({
  state: {
    app_name: 'Fred GPT',
    loading: false,
    chats: [],
    runs: [],
    run: {},
    logs: [],
    current_chat: {},
    default_prompt: '',
    summary_prompt: '',
    search_agent_prompt: '',
    section_prompt: '',
    clip_prompt: '',
    worker_prompts: [],
    variables: [],
    toasts: [],
    default_prompt_tokens: 0,
    settings: {},
    show_overview_panel: true,
    show_sources_panel: true,
    real_time_overview: true,
    clearing_logs: false,
    expanded: false
  },
  getters: {
    getChats: state => state.chats,
    getRuns: state => state.runs,
    getDefaultPrompt: state => state.default_prompt,
    getSummaryPrompt: state => state.summary_prompt,
    getSelectionPrompt: state => state.section_prompt,
    getWorkerPrompts: state => state.worker_prompts,
    getVariables: state => state.variables,
    getcurrent_chat: state => state.current_chat,
    isLoading: state => state.loading,
    getToasts: state => state.toasts,
    getDefaultPromptTokens: state => state.default_prompt_tokens,
    getLogs: state => state.logs,
    getSettings: state => state.settings,
    getAppName: state => state.app_name,
    getRun: state => state.run,
    getClipPrompt: state => state.clip_prompt,
    getSearchAgentPrompt: state => state.search_agent_prompt,
    getSectionPrompt: state => state.section_prompt,
    showOverviewPanel: state => state.show_overview_panel,
    showSourcesPanel: state => state.show_sources_panel,
    realTimeOverview: state => state.real_time_overview,
    clearingLogs: state => state.clearing_logs
  },
  mutations: {
    setShowOverviewPanel: (state, show) => {
      state.show_overview_panel = show
    },
    setShowSourcesPanel: (state, show) => {
      state.show_sources_panel = show
    },
    setRealTimeOverview: (state, show) => {
      state.real_time_overview = show
    },
    setExpanded: (state, expanded) => {
      state.expanded = expanded
    },
    setClearingLogs: (state, clearing) => {
      state.clearing_logs = clearing
    },
    setDefaultPromptTokens: (state, tokens) => {
      state.default_prompt_tokens = tokens
    },
    setToasts: (state, toasts) => {
      state.toasts = toasts
    },
    addToast: (state, toast) => {
      state.toasts.push(toast)
    },
    removeToast: (state, toastId) => {
      state.toasts = state.toasts.filter(toast => toast.id !== toastId)
    },
    clearToasts: (state) => {
      state.toasts = []
    },
    setAppName: (state, name) => {
      state.app_name = name
    },
    setRun(state, run) {
      state.run = run
    },
    setRuns: (state, runs) => {
      state.runs = runs
    },
    addRun: (state, run) => {
      state.runs.push(run)
    },
    setRunLogs: (state, logs) => {
      state.logs = logs
    },
    addRunLog: (state, log) => {
      state.logs.push(log)
    },
    removeRun(state, runId) {
      state.runs = state.runs.filter(run => run._id !== runId)
    },
    updateRun(state, run) {
      const index = state.runs.findIndex(r => r._id === run._id)
      if (index >= 0)
        state.runs[index] = run
    },
    setChats: (state, chats) => {
      state.chats = chats
    },
    setDefaultPrompt: (state, prompt) => {
      state.default_prompt = prompt;
    },
    setSummaryPrompt: (state, prompt) => {
      state.summary_prompt = prompt
    },
    setSearchAgentPrompt: (state, prompt) => {
      state.search_agent_prompt = prompt
    },
    setSectionPrompt: (state, prompt) => {
      state.section_prompt = prompt
    },
    setClipPrompt: (state, prompt) => {
      state.clip_prompt = prompt
    },
    addWorkerPrompt: (state, prompt) => {
      state.worker_prompts.push(prompt)
    },
    setWorkerPrompts: (state, prompts) => {
      state.worker_prompts = prompts
    },
    updateWorkerPrompt(state, prompt) {
      const index = state.worker_prompts.findIndex(p => p._id === prompt._id)
      if (index >= 0)
        state.worker_prompts[index] = prompt
    },
    deleteWorkerPrompt(state, prompt_id) {
      state.worker_prompts = state.worker_prompts.filter(p => p._id !== prompt_id)
    },
    setVariables: (state, variables) => {
      state.variables = variables
    },
    setCurrentChat: (state, chat) => {
      state.current_chat = chat
    },
    createChat: (state, chat) => {
      state.chats.push(chat)
    },
    deleteChat: (state, chatId) => {
      state.chats = state.chats.filter(chat => chat._id !== chatId)
    },
    renameChat: (state, { chatId, name }) => {
      const chat = state.chats.find(chat => chat._id === chatId)
      chat.metadata.name = name
    },
    addVariable: (state, variable) => {
      state.variables.push(variable)
    },
    editVariable: (state, variable) => {
      const index = state.variables.findIndex(v => v._id === variable._id)
      if (index >= 0)
        state.variables[index] = variable
    },
    deleteVariable: (state, variable_id) => {
      state.variables = state.variables.filter(v => v._id !== variable_id)
    },
    setIsLoading: (state, loading) => {
      state.loading = loading
    },
    setRunPrepare: (state, { runId, status }) => {
      const index = state.runs.findIndex(r => r._id === runId)
      if (index >= 0)
        state.runs[index].status = status
    },
    setRunStart: (state, { runId, start_time, status, total_prompts, total_prompts_tokens, total_tokens, sectionsCompleted }) => {
      const index = state.runs.findIndex(r => r._id === runId)
      if (index >= 0) {
        state.runs[index].start_time = start_time
        state.runs[index].status = status
        state.runs[index].total_prompts = total_prompts
        state.runs[index].total_prompts_tokens = total_prompts_tokens
        state.runs[index].total_tokens = total_tokens
        state.runs[index].sectionsCompleted = sectionsCompleted
      }
    },
    setRunEnd: (state, { runId, end_time, status, sectionsCompleted }) => {
      const index = state.runs.findIndex(r => r._id === runId)
      if (index >= 0) {
        state.runs[index].end_time = end_time
        state.runs[index].status = status
        state.runs[index].sectionsCompleted = sectionsCompleted
      }
    },
    setRunProgress: (state, { runId, progress, status, total_responses_tokens, current_prompt, sectionsCompleted, section_index, clip_index, task_index }) => {
      const index = state.runs.findIndex(r => r._id === runId)
      if (index >= 0) {
        state.runs[index].progress = progress
        state.runs[index].status = status
        state.runs[index].total_responses_tokens = total_responses_tokens
        state.runs[index].current_prompt = current_prompt
        state.runs[index].sectionsCompleted = sectionsCompleted
        state.runs[index].section_index = section_index
        state.runs[index].clip_index = clip_index
        state.runs[index].task_index = task_index
      }
    },
    setRunTotalResponseTokens: (state, { runId, total_responses_tokens }) => {
      const index = state.runs.findIndex(r => r._id === runId)
      if (index >= 0)
        state.runs[index].total_responses_tokens = total_responses_tokens
    },
    setSettings: (state, settings) => {
      state.settings = settings;
    }
  },
  actions: {
    solveQuery({ commit }, { query }) {
      return new Promise((resolve, reject) => {
        fetch(`${api_base}/solver/solve`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ query })
        }).then(response => response.json())
          .then(data => resolve(data))
          .catch(error => reject(error))
      })
    },
    toggleExpansion({ commit }, expanded) {
      commit('setExpanded', expanded)
    },
    toggleOverviewPanel({ commit }, show) {
      commit('setShowOverviewPanel', show)
    },
    toggleSourcesPanel({ commit }, show) {
      commit('setShowSourcesPanel', show)
    },
    toggleRealTimeOverview({ commit }, show) {
      commit('setRealTimeOverview', show)
    },
    clearLogs({ commit }, clearing = true) {
      commit('setClearingLogs', clearing)
    },
    async fetchChats({ commit }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats`);
      const chats = await response.json()
      commit('setChats', chats)
      commit('setIsLoading', false)
    },
    async updateDefaultPrompt({ commit }, { chatId, default_prompt }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/default_prompt`, {
        method: 'POST',
        body: JSON.stringify({ default_prompt }),
        headers: {
          'Content-Type': 'application/json'
        },
      });
      commit('setDefaultPrompt', default_prompt)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Default prompt updated',
        timeout: 5000
      })
    },
    async setSummaryPrompt({ commit }, { chatId, summary_prompt }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/summary_prompt`, {
        method: 'POST',
        body: JSON.stringify({ summary_prompt }),
        headers: {
          'Content-Type': 'application/json'
        },
      });
      commit('setSummaryPrompt', summary_prompt)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Summary prompt updated',
        timeout: 5000
      })
    },
    async setSearchAgentPrompt({ commit }, { chatId, search_agent_prompt }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/search_agent_prompt`, {
        method: 'POST',
        body: JSON.stringify({ search_agent_prompt }),
        headers: {
          'Content-Type': 'application/json'
        },
      });
      commit('setSearchAgentPrompt', search_agent_prompt)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Search agent prompt updated',
        timeout: 5000
      })
    },
    async setSectionPrompt({ commit }, { chatId, section_prompt }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/section_prompt`, {
        method: 'POST',
        body: JSON.stringify({ section_prompt }),
        headers: {
          'Content-Type': 'application/json'
        },
      });
      commit('setSectionPrompt', section_prompt)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Section prompt updated',
        timeout: 5000
      })
    },
    async addWorkerPrompt({ commit }, { chatId, name, prompt }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/worker_prompts`, {
        method: 'POST',
        body: JSON.stringify({ name, prompt }),
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const created_prompt = await response.json()
      commit('addWorkerPrompt', created_prompt)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Prompt created',
        timeout: 5000
      })
    },
    async updateWorkerPrompt({ commit }, { chatId, promptId, prompt, name }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/worker_prompts/${promptId}`, {
        method: 'PUT',
        body: JSON.stringify({ name, prompt }),
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const updated_prompt = await response.json()
      commit('updateWorkerPrompt', updated_prompt)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Prompt updated',
        timeout: 5000
      })
    },
    async deleteWorkerPrompt({ commit }, { chatId, prompt_id }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/worker_prompts/${prompt_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const chat = await response.json()
      if (chat.error) {
        console.log(chat.error)
        return
      }

      commit('deleteWorkerPrompt', prompt_id)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Prompt deleted',
        timeout: 5000
      })
    },
    async deleteWorkerPrompts({ commit }, { chatId, prompt_ids, ctx }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/worker_prompts`, {
        method: 'DELETE',
        body: JSON.stringify({ prompt_ids }),
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const chat = await response.json()
      if (chat.error) {
        console.log(chat.error)
        return
      }

      for (let prompt_id of prompt_ids)
        commit('deleteWorkerPrompt', prompt_id)

      if (ctx) {
        ctx.selected_worker_prompts = [];
      }

      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Prompts deleted',
        timeout: 5000
      })
    },

    async deleteChat({ commit }, { chatId }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}`, { method: 'DELETE' });
      commit('setIsLoading', false)

      const chat = await response.json()
      if (chat.error) {
        console.log(chat.error)
        return
      }

      commit('deleteChat', chatId)
      commit('setCurrentChat', null)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Chat deleted',
        timeout: 5000
      })
    },
    async fetchChat({ commit }, { chatId }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}`);
      const chat = await response.json()
      commit('setCurrentChat', chat);
      commit('setIsLoading', false)
    },

    async fetchDefaultPrompt({ commit }, { chatId }) {
      commit('setIsLoading', true)
      commit('setDefaultPrompt', '');
      const response = await fetch(`${api_base}/chats/${chatId}/default_prompt`);
      const data = await response.json()
      commit('setDefaultPrompt', data.prompt);
      commit('setIsLoading', false)
    },

    async fetchSummaryPrompt({ commit }, { chatId }) {
      commit('setIsLoading', true)
      commit('setSummaryPrompt', '');
      const response = await fetch(`${api_base}/chats/${chatId}/summary_prompt`);
      const data = await response.json()
      commit('setSummaryPrompt', data.prompt);
      commit('setIsLoading', false)
    },

    async fetchSearchAgentPrompt({ commit }, { chatId }) {
      commit('setIsLoading', true)
      commit('setSearchAgentPrompt', '');
      const response = await fetch(`${api_base}/chats/${chatId}/search_agent_prompt`);
      const data = await response.json()
      commit('setSearchAgentPrompt', data.prompt);
      commit('setIsLoading', false)
    },

    async fetchSectionPrompt({ commit }, { chatId, parse_vars = false }) {
      commit('setIsLoading', true)
      commit('setSectionPrompt', '');
      const response = await fetch(`${api_base}/chats/${chatId}/section_prompt${parse_vars ? '?parse_vars=true' : ''}`);
      const data = await response.json()
      commit('setSectionPrompt', data.prompt);
      commit('setIsLoading', false)
    },

    async fetchClipPrompt({ commit }, { chatId, parse_vars = false }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/clip_prompt${parse_vars ? '?parse_vars=true' : ''}`);
      const data = await response.json()
      commit('setIsLoading', false)
      commit('setClipPrompt', data.prompt)
    },

    async fetchWorkerPrompts({ commit }, { chatId }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/worker_prompts`);
      const data = await response.json()
      commit('setWorkerPrompts', data);
      commit('setIsLoading', false)
    },

    async fetchVariables({ commit }, { chatId }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/variables`);
      const data = await response.json()
      commit('setVariables', data);
      commit('setIsLoading', false)
    },

    async fetchRuns({ commit }, { chatId }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/runs`);
      const data = await response.json()
      commit('setRuns', data);
      commit('setIsLoading', false)
    },

    async fetchRun({ commit }, { chatId, runId }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/runs/${runId}`);
      const data = await response.json()
      commit('setRun', data);
      commit('setIsLoading', false)
    },

    async createChat({ commit }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats`, { method: 'POST' });
      const chat = await response.json()
      commit('createChat', chat)
      commit('setCurrentChat', chat)
      commit('setDefaultPrompt', chat.metadata.default_prompt);
      commit('setSummaryPrompt', chat.metadata.summary_prompt);
      commit('setSearchAgentPrompt', chat.metadata.search_agent_prompt);
      commit('setSectionPrompt', chat.metadata.section_prompt);
      commit('setClipPrompt', chat.metadata.clip_prompt);
      commit('setVariables', chat.metadata.variables);
      commit('setRuns', chat.runs);
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Chat created',
        timeout: 5000
      })

      return chat
    },
    async setRunDry({ commit }, { chatId, runId, dry_run }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/runs/${runId}/dry_run`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ dry_run })
      });

      const run = await response.json()
      commit('updateRun', run)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: dry_run ? 'Dry run enabled' : 'Dry run disabled',
        timeout: 5000
      })

      return run
    },
    async renameChat({ commit }, { chatId, name }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/name`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name })
      });
      const chat = await response.json()
      commit('setCurrentChat', chat);
      commit('renameChat', { chatId, name })
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Chat renamed',
        timeout: 5000
      })
    },
    async addVariable({ commit }, { chatId, variable }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/variables`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ variable })
      });

      if (response.error) {
        console.log(response.error)
        return
      }

      const created_var = await response.json()
      commit('addVariable', created_var)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Variable created',
        timeout: 5000
      })
    },
    async editVariable({ commit }, { chatId, variable }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/variables`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ variables: [variable] })
      });

      const chat = await response.json()
      if (chat.error) {
        console.log(chat.error)
        return
      }

      commit('editVariable', variable)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Variable updated',
        timeout: 5000
      })
    },
    async deleteVariable({ commit }, { chatId, variable_id }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/variables/${variable_id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const chat = await response.json()
      if (chat.error) {
        console.log(chat.error)
        return
      }

      commit('deleteVariable', variable_id)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Variable deleted',
        timeout: 5000
      })
    },
    async createChatRun({ commit }, { chatId, run, ctx }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/runs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          api: run.api,
          name: run.name,
          model: run.model,
          temperature: run.temperature,
          dry_run: run.dry_run,
          search_agent_enabled: run.search_agent_enabled,
          search_agent_model: run.search_agent_model,
          search_agent_temperature: run.search_agent_temperature,
          search_agent_max_search_results: run.search_agent_max_search_results,
        })
      });

      const data = await response.json()
      commit('addRun', data)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Run created',
        timeout: 5000
      })

      ctx.$router.push({ name: 'monitor_run', params: { chatId, runId: data._id } })
    },

    async deleteChatRun({ commit }, { chatId, runId }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/runs/${runId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const chat = await response.json()
      if (chat.error) {
        console.log(chat.error)
        return
      }

      commit('removeRun', runId)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Run deleted',
        timeout: 5000
      })
    },

    async startChatRun({ commit }, { chatId, runId, is_restart }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/runs/${runId}/prepare`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ is_restart })
      });

      const run = await response.json()
      commit('updateRun', run)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Run started',
        timeout: 5000
      })
    },
    async backup({ commit }, { filename }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/export`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ filename })
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = response.headers.get('x-filename');
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);

        commit('setIsLoading', false);
        commit('addToast', {
          id: Math.random().toString(36).substr(2, 9),
          type: 'success',
          text: 'Backup completed successfully.',
          timeout: 5000
        });
      } else {
        const data = await response.json();
        commit('setIsLoading', false);
        commit('addToast', {
          id: Math.random().toString(36).substr(2, 9),
          type: 'error',
          text: data.error || 'Backup failed.',
          timeout: 5000
        });
      }
    },

    async restore({ commit }, { filename }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/import`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ filename })
      });

      const data = await response.json()
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: data.message,
        timeout: 5000
      })
    },

    async setClipPrompt({ commit }, { chatId, clip_prompt }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/clip_prompt`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ clip_prompt })
      });

      const run = await response.json()
      commit('updateRun', run)
      commit('setIsLoading', false)
    },

    async stopChatRun({ commit }, { chatId, runId }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/runs/${runId}/stop`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const run = await response.json()
      commit('updateRun', run)
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Run stopped',
        timeout: 5000
      })

      return run
    },

    addToast({ commit }, toast) {
      commit('addToast', toast)
    },

    removeToast({ commit }, toastId) {
      commit('removeToast', toastId)
    },

    clearToasts({ commit }) {
      commit('clearToasts')
    },

    async duplicateChat({ commit }, { chatId }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/duplicate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      const chat = await response.json()
      commit('createChat', chat)
      commit('setCurrentChat', chat)
      commit('setDefaultPrompt', chat.metadata.default_prompt);
      commit('setSummaryPrompt', chat.metadata.summary_prompt);
      commit('setSearchAgentPrompt', chat.metadata.search_agent_prompt);
      commit('setSectionPrompt', chat.metadata.section_prompt);
      commit('setClipPrompt', chat.metadata.clip_prompt);
      commit('setVariables', chat.metadata.variables);
      commit('setRuns', chat.runs);
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Chat duplicated',
        timeout: 5000,
      })
    },

    async setLoading({ commit }, loading) {
      commit('setIsLoading', loading)
    },

    async countTokens({ commit }, { text }) {
      const response = await fetch(`${api_base}/count_tokens`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text })
      });

      const data = await response.json();
      return data.tokens;
    },

    setRunStart({ commit }, { runId, start_time, status, total_prompts, total_prompts_tokens, total_tokens, sectionsCompleted }) {
      commit('setRunStart', { runId, start_time, status, total_prompts, total_prompts_tokens, total_tokens, sectionsCompleted })
    },

    setRunPrepare({ commit }, { runId, status }) {
      commit('setRunPrepare', { runId, status })
    },

    setRunEnd({ commit }, { runId, end_time, status, sectionsCompleted }) {
      commit('setRunEnd', { runId, end_time, status, sectionsCompleted })
      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Run finished',
        timeout: 5000,
      })
    },
    setRunTotalResponseTokens({ commit }, { runId, total_responses_tokens }) {
      commit('setRunTotalResponseTokens', { runId, total_responses_tokens })
    },
    setRunProgress({ commit }, { runId, progress, status, total_responses_tokens, current_prompt, sectionsCompleted, section_index, clip_index, task_index }) {
      commit('setRunProgress', { runId, progress, status, total_responses_tokens, current_prompt, sectionsCompleted, section_index, clip_index, task_index })
    },

    async setRunLogs({ commit }, { chatId, runId }) {
      const response = await fetch(`${api_base}/chats/${chatId}/runs/${runId}/logs`);
      commit('setRunLogs', await response.json())
    },

    async updateWorkerPromptsOrder({ commit }, { chatId, promptsIds }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/worker_prompts_order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ promptsIds })
      });

      const chat = await response.json()
      commit('setWorkerPrompts', chat.metadata.worker_prompts);
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Prompts order updated',
        timeout: 5000,
      })
    },
    async downloadOutput({ commit }, { chatId, runId, chat_name, run_name, current_date, include_sequence_descriptions, is_pdf }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/chats/${chatId}/runs/${runId}/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ include_sequence_descriptions, is_pdf })
      });

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${current_date}_${chat_name}_${run_name}${is_pdf == 'off' ? '.pdf' : '.docx'}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      commit('setIsLoading', false)
    },
    async saveSettings({ commit }, settings) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ settings })
      });

      const data = await response.json()
      commit('setIsLoading', false)

      commit('addToast', {
        id: Math.random().toString(36).substr(2, 9),
        type: 'success',
        text: 'Settings saved',
        timeout: 5000,
      })
    },

    async setSettings({ commit }) {
      commit('setIsLoading', true)
      const response = await fetch(`${api_base}/settings`);
      const data = await response.json()
      commit('setSettings', data)
      commit('setIsLoading', false)
    }
  }
})
