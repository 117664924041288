<template>
    <div class="relative">
        <section class="bg-gray-50 dark:bg-gray-900 flex items-center">
            <div class="w-full">
                <div class="relative bg-white shadow-md dark:bg-gray-800 ">
                    <div
                        class="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                        <div class="w-full md:w-1/2">
                            <form class="flex items-center">
                                <label for="simple-search" class="sr-only">Search...</label>
                                <div class="relative w-full">
                                    <div class="relative">
                                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                            <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                                fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                    clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <input type="text" id="simple-search"
                                            class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                            placeholder="Search..." required="" v-model="search_query">
                                        <div class="absolute right-3 top-3 bottom-0 cursor-pointer"
                                            @click="search_query = ''" v-show="search_query.length > 0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                fill="currentColor" class="bi bi-x-circle dark:fill-gray-400"
                                                viewBox="0 0 16 16">
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                <path
                                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div
                            class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                            <button type="button"
                                class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 flex justify-center text-center text-center mb-1"
                                @click="showModalCreate = true">
                                <div class="flex flex-row items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                        style="transform: translateY(0px) scale(1.5) " class="pt-1 mr-1"
                                        viewBox="0 0 20 20">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                    </svg>
                                    <div>Add variable</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-2 py-2">
                        Key
                    </th>
                    <th scope="col" class="px-2 py-2 lg:block hidden">
                        Value
                    </th>
                    <th scope="col" class="px-2 py-2 text-center">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="cursor-pointer bg-white border-b dark:bg-gray-800 dark:border-gray-700 dark:border-opacity-50 text-gray-900 dark:text-gray-400 hover:dark:text-gray-100"
                    @click="edited_var = variable; showModal = true" v-for="(variable, index) in variables_filtered"
                    :key="variable._id">
                    <td scope="row" class="px-2 py-2 font-medium whitespace-nowrap">
                        <div v-if="editing_var && editing_var_id == variable._id">
                            <input type="text" name="editing_var_key" v-model="editing_var_key"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                required @keyup.enter="set_edit_variable()" @keyup.escape="editing_var = false"
                                placeholder="Example: DOCUMENT_TITLE">
                        </div>
                        <div v-else class="w-72 lg:w-full font-semibold truncate" v-html="variable.key">
                        </div>
                    </td>
                    <td class="px-2 py-2 lg:block hidden">
                        <div v-if="editing_var && editing_var_id == variable._id">
                            <input type="text" name="editing_var_value" v-model="editing_var_value"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                required @keyup.enter="set_edit_variable()" @keyup.escape="editing_var = false">
                        </div>
                        <div v-else class="line-clamp-1">{{ variable.value }}</div>
                    </td>
                    <td>
                        <div :data-dropdown-toggle="'dropdown_vars_list_toggle_' + variable._id"
                            :id="'dropdown_vars_list_menu_' + variable._id" @click.stop
                            class="w-8 h-6 justify-center flex w-full items-center">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400 mx-auto" aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 4 15">
                                <path
                                    d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                            </svg>
                        </div>
                        <div :id="'dropdown_vars_list_toggle_' + variable._id"
                            class="z-50 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 shadow-lg">
                            <ul class="text-sm text-gray-700 dark:text-gray-200"
                                :aria-labelledby="'dropdown_vars_list_toggle_' + variable._id">
                                <li>
                                    <a href="#" @click.prevent.stop="showEditVariableModal(variable)"
                                        class="block px-4 py-1 hover:rounded-t-lg hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit</a>
                                </li>
                                <li>
                                    <a href="#"
                                        class="block px-4 py-1 hover:rounded-lg hover:bg-gray-100 border-t border-gray-200 dark:border-gray-800 dark:bg-red-950 dark:bg-opacity-50 dark:hover:text-red-300 flex flex-row text-red-500"
                                        @click.prevent.stop="showConfirmModal(variable)">
                                        <span>Delete</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </td>
                </tr>
                <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700" v-if="creating_var">
                    <th scope="row" class="px-2 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <input type="text" v-model="creating_var_key"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Example: DOCUMENT_TITLE" ref="creating_var_key">
                    </th>
                    <td class="px-2 py-2">
                        <input type="text" v-model="creating_var_value"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            required @keyup.enter="add_variable()" @keyup.esc="creating_var = false">
                    </td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
    <EditVariableModal title="Edit variable" :variable="edited_var" :isVisible="showModal" v-if="edited_var"
        @update:isVisible="handleModalVisibility" @update:variable="updated_variable" @update:remove="removed_variable">
    </EditVariableModal>
    <EditVariableModal title="Create variable" :variable="created_var" :creating="true" :isVisible="showModalCreate"
        v-if="created_var" @update:isVisible="handleCreateModalVisibility" @update:variable="created_variable">
    </EditVariableModal>
    <ConfirmModal title="Delete variable" content="Are you sure you want to delete this variable ?" :item="confirmItem"
        :isVisible="showModalConfirm" v-if="confirmItem" @update:isVisible="handleConfirmModalVisibility"
        @update:confirmed="delete_variable"></ConfirmModal>
</template>
<script>
import { initDropdowns } from 'flowbite';
import EditVariableModal from '@/components/modals/edit_variable.vue';
import ConfirmModal from '@/components/modals/confirm.vue';

export default {
    components: {
        EditVariableModal,
        ConfirmModal
    },
    data() {
        return {
            current_chat: null,
            showModal: false,
            showModalCreate: false,
            showModalConfirm: false,
            confirmItem: null,
            created_var: {
                key: '',
                value: ''
            },
            creating_var: false,
            creating_var_key: '',
            creating_var_value: '',
            edited_var: null,
            editing_var: false,
            editing_var_id: '',
            editing_var_key: '',
            editing_var_value: '',
            search_query: ''
        }
    },
    mounted() {
        this.$nextTick(() => {
            initDropdowns();
        });

        this.current_chat = {
            _id: this.$route.params.chatId
        };

        this.$store.dispatch('fetchChat', {chatId: this.$route.params.chatId});
        this.$store.dispatch('fetchVariables', {
            chatId: this.current_chat._id
        });
    },
    methods: {
        showEditVariableModal(variable) {
            this.edited_var = variable;
            this.hideDropdown(variable._id);
            this.showModal = true;
        },
        handleModalVisibility(newValue) {
            this.showModal = newValue;
        },
        handleCreateModalVisibility(newValue) {
            this.showModalCreate = newValue;
        },
        handleConfirmModalVisibility(newValue) {
            this.showModalConfirm = newValue;
        },
        hideDropdown(variable_id) {
            this.$nextTick(() => {
                const target = document.querySelector('#dropdown_vars_list_toggle_' + variable_id);
                const trigger = document.querySelector('#dropdown_vars_list_menu_' + variable_id);
                if (!target || !trigger) return;
                const dropdown = new Dropdown(target, trigger, {
                    override: true,
                    triggerType: 'click',
                });
                dropdown.hide();
            });
        },
        showConfirmModal(variable) {
            this.confirmItem = variable;
            this.hideDropdown(variable._id);
            this.showModalConfirm = true;
        },
        create_variable() {
            this.creating_var = true;
            this.$nextTick(() => {
                this.$refs.creating_var_key.focus();
            });
        },
        created_variable(variable) {
            this.$store.dispatch('addVariable', {
                chatId: this.current_chat._id,
                variable: variable
            });
        },
        removed_variable(variable) {
            this.confirmItem = variable;
            this.showModalConfirm = true;
        },
        edit_variable(variable) {
            this.editing_var = true;
            this.editing_var_key = variable.key;
            this.editing_var_value = variable.value;
            this.editing_var_id = variable._id
        },
        async set_edit_variable() {
            await this.$store.dispatch('editVariable', {
                chatId: this.current_chat._id,
                variable: {
                    _id: this.editing_var_id,
                    key: this.editing_var_key,
                    value: this.editing_var_value
                }
            });
            this.editing_var = false;
            this.editing_var_key = '';
            this.editing_var_value = '';
            this.editing_var_id = '';
        },
        delete_variable(variable) {
            if (!variable) return;
            this.$store.dispatch('deleteVariable', {
                chatId: this.current_chat._id,
                variable_id: variable._id
            });
        },
        async add_variable() {
            await this.$store.dispatch('addVariable', {
                chatId: this.current_chat._id,
                variable: {
                    key: this.creating_var_key,
                    value: this.creating_var_value
                }
            });
            this.creating_var = false;
            this.creating_var_key = '';
            this.creating_var_value = '';
        },
        async updated_variable(variable) {
            await this.$store.dispatch('editVariable', {
                chatId: this.current_chat._id,
                variable: {
                    _id: variable._id,
                    key: variable.key,
                    value: variable.value
                }
            });
        }
    },
    computed: {
        variables_computed() {
            const vars = this.$store.state.variables;
            this.$nextTick(() => {
                initDropdowns();
            });
            return vars;
        },
        variables_filtered() {
            if (this.search_query == '')
                return this.variables_computed;

            let query = this.search_query
                .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
                .toUpperCase()
                .replace(/[^A-Z0-9_]/gi, '_');

            let filtered = this.variables_computed.filter(variable =>
                variable.key.includes(query));

            filtered = filtered.map(variable => {
                let key = variable.key;
                let regex = new RegExp(query, 'gi');
                key = key.replace(regex, `<span class="search-highlight text-gray-800">$&</span>`);
                return {
                    ...variable,
                    key: key
                }
            });

            return filtered;
        }
    },
    watch: {
        '$route.params.chatId': {
            immediate: true,
            handler(newVal) {
                this.current_chat = {
                    _id: newVal
                };
                this.$store.dispatch('fetchVariables', {
                    chatId: this.current_chat._id
                });
            }
        },
    },
    updated() {
        this.$nextTick(() => {
            initDropdowns();
        });
    }
}
</script>