<template>
    <section class="bg-gray-50 dark:bg-gray-900 flex items-center">
        <div class="w-full">
            <div class="relative bg-white shadow-md dark:bg-gray-800 ">
                <div class="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                    <div class="w-full md:w-1/2">
                        <form class="flex items-center">
                            <label for="simple-search" class="sr-only">Search...</label>
                            <div class="relative w-full">
                                <div class="relative">
                                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400"
                                            fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd"
                                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <input type="text" id="simple-search"
                                        class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-900 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                                        placeholder="Search..." required="" v-model="search_query">
                                    <div class="absolute right-3 top-3 bottom-0 cursor-pointer" @click="search_query = ''"
                                        v-show="search_query.length > 0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                            class="bi bi-x-circle dark:fill-gray-400" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                            <path
                                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div
                        class="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                        <button type="button"
                            class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2 flex justify-center text-center text-center mb-1"
                            @click="create_chat_run">
                            <div class="flex flex-row items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                                    style="transform: translateY(0px) scale(1.5) " class="pt-1 mr-1" viewBox="0 0 20 20">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                </svg>
                                <div>Create chat run</div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="relative overflow-x-auto">
        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-2 py-2">
                        Name
                    </th>
                    <th scope="col" class="px-2 py-2 text-center">
                        Progress
                    </th>
                    <th scope="col" class="px-2 py-2 text-center">
                        Status
                    </th>
                    <th scope="col" class="px-2 py-2 text-center">
                        Est.&nbsp;Cost
                    </th>
                    <th scope="col" class="px-2 py-2 text-center">
                        Actions
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="cursor-pointer bg-white border-b dark:bg-gray-800 dark:border-gray-700 dark:border-opacity-50 text-gray-900 dark:text-gray-400 hover:dark:text-gray-100"
                    v-for="run in runs" @click.stop.prevent="open_chat_run(run._id)">
                    <td class="px-2 py-2">
                        {{ run.name }}
                    </td>
                    <td class="px-2 py-2 text-center">
                        <Gauge :progress="run.progress" :width="22" :height="22" width_class="w-10" height_class="h-10"
                            :stroke_width="1" class="mx-auto" />
                    </td>
                    <td class="px-2 py-2 text-center font-semibold uppercase">
                        <span :class="get_run_status_class(run)">{{ run.status }}</span>
                    </td>
                    <td class="px-2 py-2 text-center">
                        {{ format_price(calculate_input_price(prices, run.model,
                                run.total_prompts_tokens) + calculate_output_price(prices, run.model,
                                run.total_responses_tokens)) }}
                    </td>
                    <td class="px-2 py-2 text-center">
                        <div class="flex flex-row items-center justify-center">
                            <button @click.stop.prevent="start_chat_run(run)" v-if="run.status == 'created'"
                                class="px-2 py-2 text-sm font-medium text-green-900 bg-white border border-green-200 rounded-full hover:bg-green-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-green-950 dark:bg-opacity-50 dark:border-green-500 dark:hover:border-green-400 dark:text-green-400 dark:hover:text-green-300 dark:hover:bg-green-950 dark:hover:bg-opacity-50 dark:focus:ring-blue-500 dark:focus:text-white float-left flex flex-row items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-play-fill" viewBox="0 0 16 16">
                                    <path
                                        d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z" />
                                </svg>
                            </button>
                            <button @click.stop.prevent="stop_chat_run(run)" v-if="run.status == 'running'"
                                class="px-2 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-full hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-950 dark:bg-opacity-50 dark:border-gray-500 dark:hover:border-gray-400 dark:text-gray-400 dark:hover:text-gray-300 dark:hover:bg-gray-950 dark:hover:bg-opacity-50 dark:focus:ring-blue-500 dark:focus:text-white float-left flex flex-row items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-stop-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5A1.5 1.5 0 0 1 5 3.5" />
                                </svg>
                            </button>
                            <button @click.stop.prevent="delete_chat_run(run)"
                                class="px-2 py-2 text-sm font-medium text-red-900 bg-white border border-red-200 rounded-full hover:bg-red-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-red-950 dark:bg-opacity-50 dark:border-red-500 dark:hover:border-red-400 dark:text-red-400 dark:hover:text-red-300 dark:hover:bg-red-950 dark:hover:bg-opacity-50 dark:focus:ring-blue-500 dark:focus:text-white float-left flex flex-row items-center ml-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-trash" viewBox="0 0 16 16">
                                    <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                    <path
                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                </svg>
                            </button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <EditChatRunModal title="Edit chat run" :run="edited_run" :isVisible="showModal" v-if="edited_run"
        @update:isVisible="handleModalVisibility" @update:run="updated_run" @update:remove="removed_run">
    </EditChatRunModal>
    <EditChatRunModal title="Create chat run" :run="created_run" :creating="true" :isVisible="showModalCreate"
        @update:isVisible="handleCreateModalVisibility" @update:run="on_created_run">
    </EditChatRunModal>
    <ConfirmModal title="Delete chat run" content="Are you sure you want to delete this chat run ?" :item="confirmItem"
        :isVisible="showModalConfirm" v-if="confirmItem" @update:isVisible="handleConfirmModalVisibility"
        @update:confirmed="delete_run"></ConfirmModal>
</template>
<script>
import EditChatRunModal from '@/components/modals/edit_chat_run.vue';
import ConfirmModal from '@/components/modals/confirm.vue';
import TimeMixin from '@/mixins/time.js';
import PricingMixin from '@/mixins/pricing.js';
import Gauge from '@/components/gauge.vue';

export default {
    name: 'Monitor',
    mixins: [
        TimeMixin,
        PricingMixin
    ],
    components: {
        EditChatRunModal,
        ConfirmModal,
        Gauge
    },
    data() {
        return {
            prices: [],
            current_chat: null,
            runs: [],
            showModal: false,
            showModalCreate: false,
            showModalConfirm: false,
            confirmItem: null,
            created_run: {
                name: '',
                model: 'mixtral',
                temperature: 0.1
            },
            creating_run: false,
            edited_run: null,
            editing_run: false,
            search_query: ''
        }
    },

    async mounted() {
        this.current_chat = {
            _id: this.$route.params.chatId
        };

        this.$store.dispatch('fetchChat', { chatId: this.$route.params.chatId });
        await this.$store.dispatch('fetchRuns', {
            chatId: this.current_chat._id
        });
    },
    methods: {
        open_chat_run(runId) {
            this.$router.push({
                name: 'monitor_run',
                params: {
                    chatId: this.$route.params.chatId,
                    runId
                }
            });
        },
        get_run_status_class(run) {
            if (run.status == 'created') return 'bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300';
            if (run.status == 'started') return 'bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300';
            if (run.status == 'preparing') return 'bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300';
            if (run.status == 'running') return 'bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300';
            if (run.status == 'stopped') return 'bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300';
            if (run.status == 'finished') return 'bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300';
            if (run.status == 'errored') return 'bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300';
            return '';
        },
        run_duration(run) {
            if (!run.start_time) return 0;
            if (!run.end_time) return (new Date().getTime() / 1000 - run.start_time).toFixed(0)
            return (run.end_time - run.start_time).toFixed(0)
        },
    
        handleModalVisibility(value) {
            this.showModal = value;
        },
        handleCreateModalVisibility(value) {
            this.showModalCreate = value;
        },
        handleConfirmModalVisibility(value) {
            this.showModalConfirm = value;
        },
        updated_run(run) {
            this.edited_run = run;
            this.$store.dispatch('updateChatRun', {
                chatId: this.current_chat._id,
                runId: run._id,
                run: run
            });
        },
        removed_run(run) {
            this.edited_run = null;
            this.$store.dispatch('deleteChatRun', {
                chatId: this.current_chat._id,
                runId: run._id
            });
        },
        delete_run(run) {
            this.confirmItem = null;
            this.$store.dispatch('deleteChatRun', {
                chatId: this.current_chat._id,
                runId: run._id
            });
        },
        on_created_run(run) {
            this.created_run = run;
            this.$store.dispatch('createChatRun', {
                chatId: this.current_chat._id,
                run: run,
                ctx: this
            });
        },
        format_number(num) {
            return num.toLocaleString('fr-FR').replace(/,/g, ' ');
        },
        async create_chat_run() {
            this.created_run = {
                name: '',
                api: 'custom',
                model: 'mixtral_8x7b',
                temperature: 0.1
            };
            this.showModalCreate = true;
        },
        async delete_chat_run(run) {
            this.confirmItem = run;
            this.showModalConfirm = true;
        },
        async start_chat_run(run) {
            this.$store.dispatch('startChatRun', {
                chatId: this.current_chat._id,
                runId: run._id
            });
        },
        async stop_chat_run(run) {
            this.$store.dispatch('stopChatRun', {
                chatId: this.current_chat._id,
                runId: run._id
            });
        },
    },
    computed: {
        prices_computed() {
            return this.$store.state.settings.prices;
        }
    },
    watch: {
        '$store.state.runs'(val) {
            this.runs = val;
        },
        '$route.params.chatId': {
            immediate: true,
            handler(newVal) {
                this.current_chat = {
                    _id: newVal
                };

                this.$store.dispatch('fetchRuns', {
                    chatId: this.current_chat._id
                });
            }
        },
        prices_computed: {
            handler(val, oldVal) {
                this.prices = val;
            },
            immediate: true
        }
    }
}
</script>